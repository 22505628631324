<template>
    <div class="page-with-menu">
      <DataList
      class="card-list"
      v-if="caseData"
      :dataList="caseData"
      :totalCount="totalCount"
      :pageSize="8"
      :hasPagination="true"
      showType="CardList"
      showSubType="CardItemZyb"
      @handlePageChange="handlePagination"
    
    ></DataList>
    </div>
  
</template>

<script>
import DataList from '@/components/DataList/DataList.vue';
import {getResourceData} from '../../api/api'
const pageSize = 8;
export default {
  components: {
    DataList
  },
  data() {
    return {
      caseData:[],
      totalCount : 0
    };
  },
  methods: {
    async fetchData(val=0){
      const caseData = await getResourceData(val,pageSize);
      console.log(caseData)
      this.caseData = caseData.data.list;
      this.totalCount = caseData.data.count;

    },
    // 分页
    handlePagination(val) {
      
      this.fetchData(val,pageSize);
    }
  },
  mounted(){
    this.fetchData();
  }
}
</script>

<style scoped lang="less">
.page-with-menu {
    padding-top: 55px;
    padding-left:30px;
  }

  @media (max-width: 798px) {
    .page-with-menu {
      padding-top: .3rem;
      padding-left:0;
    }
  }
</style>