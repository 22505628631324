<template>
  <div class="container">
    <BreadCrumb :crumbData="breadCrumb" class="jyfx-bc"></BreadCrumb>
    <div class="sub-title">经验分享</div>
    <DataList
      v-if="dynamicList"
      :dataList="dynamicList"
      :totalCount="count"
      :hasPagination="true"
      :pageSize="10"
      @handlePageChange="handlePagination"
      showType="CardList"
      showSubType="NewsListItemList"
    ></DataList>
  </div>
  
</template>

<script>
import DataList from '../../components/DataList/DataList.vue'
import {getDynamicList} from '../../api/api'
import BreadCrumb from '../../components/BreadCrumb.vue'
export default {
  components: {
    DataList,BreadCrumb
  },
  data () {
    return {
      dynamicList : [],
      type : 3, //经验分享
      page : 1, 
      size : 10,
      count : null,
      breadCrumb:[
       {name:'参与教师',url:'/#/participate/teacher'},{name:'经验分享'}
      ],
    }
  },
  created () {
    this.getDynamicData()
  },

  methods: {
    
    // 通知公告列表
    async getDynamicData() {
      let params = {
        type : this.type,
        page : this.page,
        size : this.size
      }
      const res = await getDynamicList(params)
      this.dynamicList = res.data.list
      this.count = res.data.count
    },
    // 分页
    handlePagination(val) {
      this.page = val
      this.getDynamicData()
    }
  }
}
</script>

<style scoped lang="less">
.sub-title{
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
  color:#5293d2;
}
@media (max-width: 768px) {
  .jyfx-bc{
    margin-top:-.3rem;
    margin-bottom:.3rem;
  }
  .sub-title{
    font-size: .32rem;
    margin-bottom: .2rem;
    color:#5293d2;
  }
  .container {
    width: 100%;
    margin-left: 0;
    padding-top: 0;
  }
}
</style>