<template>
  <div class="container">
    <el-select v-model="value" placeholder="请选择年份" @change="filterData(value)">
      <el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.value"> </el-option>
    </el-select>
    <DataList
      v-if="imageNewsData"
      :dataList="imageNewsData"
      :totalCount="count"
      :hasPagination="true"
      :pageSize="size"
      @handlePageChange="handlePagination"
      showType="CardList"
      showSubType="CardActivityItem"
    ></DataList>
  </div>
</template>

<script>
import DataList from '../../components/DataList/DataList.vue'
import { getDynamicList } from '../../api/api'
export default {
  data () {
    return {
      options: [{
        value: '2022',
      }, {
        value: '2021',
      }, {
        value: '2020',
      }, {
        value: '2019',
      }, {
        value: '2018',
      },
      {
        value: '2017',
      },
      {
        value: '2016',
      },
      {
        value: '2015',
      },
      {
        value: '2014',
      },
      {
        value: '2013',
      },
      {
        value: '2012',
      },
      {
        value: '2011',
      },
      {
        value: '2010',
      },
      {
        value: '2009',
      },
      {
        value: '2008',
      },
      {
        value: '2007',
      },
      ],
      imageNewsData: [],
      value: '',
      page: 1,
      size: 10,
      year: '',
      count: null,
      type : 2,
    }
  },
  components: {
    DataList
  },
  created () {
    this.getDynamicData()
  },
  methods: {
    // 活动报道列表
    async getDynamicData () {
      let params = {
        type: this.type,
        page: this.page,
        size: this.size,
        year: this.year
      }
      const res = await getDynamicList(params)
      this.imageNewsData = res.data.list
      this.count = res.data.count
    },
    // 分页
    handlePagination (val) {
      this.page = val
      this.getDynamicData()
    },
    // 年份筛选
    filterData(value) {
      this.year = value 
      this.getDynamicData()
    }
  }
}
</script>

<style scoped lang="less">
.container {
  width: 890px;
  margin-left: 31px;
  padding-top: 55px;
  padding-bottom: 150px;
  /deep/.el-select {
    margin-bottom: 40px;
  }
  /deep/.el-input__suffix {
    right: 0px;
    width: 37px;
    background: var(--main-color-1);
    color: #fff;
  }
  /deep/.el-select .el-input .el-select__caret {
    color: #fff;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .container {
    width: 100%;
    margin-left: 0;
    padding-top: 0;
    padding-bottom: 1.04rem;
    /deep/.el-select {
      width: 100%;
      margin-top: 0.2rem;
      margin-bottom: 0.37rem;
    }
    /deep/.el-input__suffix {
      right: 0px;
      width: 0.7rem;
    }
    /deep/.el-select .el-input .el-select__caret {
      color: #fff;
      font-size: 0.36rem;
      line-height: 1;
    }
  }
}
</style>