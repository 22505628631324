<template>
  <router-link :to="cardData.link" class="item-container">
    <img :src="cardData.image" alt="" />
    <div class="news">
      <div class="news-title">
        <span class="more">{{ cardData.title }}</span>
      </div>
      <div class="date">
        <img src="../../assets/images/time.jpg" alt="" />
        <span v-if="cardData.publishTime">{{ cardData.publishTime.slice(0, 10) }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'CardActivityItem',
  props: {
    cardData: {
      type: Object,
      require: true
    },
  }
}
</script>

<style scoped lang="less">
.item-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 49px;
  img {
    width: 308px;
    height: 175px;
    margin-right: 30px;
  }
  .news {
    position: relative;
    border-bottom: 2px solid #eee;
    width: 541px;
    &::after {
      content: ' ';
      position:absolute;
      bottom:-2px;
      left:0;
      right:0;
      height: 2px;
      background-color: #5293D2;
      width:0%;
      transition:all .2s ease-in-out;
      }
      &:hover{
      &::after{
        width:100%;
      }
      .news-title{
        color:#5293D2;
      }
    }
    .news-title {
      cursor: pointer;
      font-size: 20px;
      color: #111111;
      line-height: 32px;
      margin-bottom: 71px;
      letter-spacing: 1px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .date {
      position: absolute;
      color:#666;
      bottom: 25px;
      display: flex;
      align-items: center;
      img {
        width: 15px;
        height: 15px;
        margin-right: 8px;
      }
    }
  }
  
}
@media (max-width: 768px) {
  .item-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.37rem;
    img {
      width: 3.08rem;
      height: 1.73rem;
      margin-right: 0.3rem;
    }
    .news {
      width: 3.48rem;
      .news-title {
        font-size: 0.28rem;
        line-height: 0.44rem;
        margin-bottom: 0.49rem;
        letter-spacing: inherit;
      }
      .date {
        bottom: 0.21rem;
        img {
          width: 0.22rem;
          height: 0.22rem;
          margin-right: 0.12rem;
        }
      }
    }
  }
}
</style>