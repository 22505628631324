<template>
  <div class="question-container">
    <div class="question-item" v-for="item in questionData" :key="item.id">
      <div class="question-name">
        <img src="../../assets/images/0.png" />
        {{ item.title }}
      </div>
      <div class="answer" v-html="item.content"></div>
    </div>
    <div class="hint" v-if="questionData.length">
      <div class="ques-other">如有其他问题，请发邮件至活动邮箱scienceactivity@cast.org.cn询问。</div>
    </div>
  </div>
</template>

<script>
import {getProblemsList} from '../../api/api'
export default {
  data () {
    return {
      questionData: [],
      type : ''
    }
  },
  created () {
    this.getQuetionList()
  },
  methods: {
    // 获取常见问题列表
    async getQuetionList () {
      if(this.$route.path.includes('all')) {
        this.type = ''
      } else if (this.$route.path.includes('student')) {
        this.type = 2
      } else if (this.$route.path.includes('teacher')) {
        this.type = 1
      } else {
        this.type = 3
      }
      const res = await getProblemsList(this.type)
      this.questionData = res.data
    }
  }
}
</script>

<style scoped lang="less">
.question-container {
  width: 890px;
  margin-left: 31px;
  padding-top: 55px;
  .question-item {
    width: 100%;
    padding-bottom: 35px;
    border-bottom: 1px solid #eee;
    margin-bottom:30px;
    .question-name {
      font-size: 20px;

      color: #111111;
      line-height: 32px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 16px;
      }
    }
    .answer {
      font-size: 16px;
      color: #5293d2;
      padding-left: 28px;
      line-height: 32px;
    }
  }
  .hint {
    width: 100%;
    text-align: center;
    margin-top: 43px;
    .ques-other {
      background: #bdc2c5;
      height: 50px;
      display: inline-block;
      padding: 0 15px 0 50px;
      border-radius: 25px;
      margin: 0 auto;
      color: #fff;
      line-height: 50px;
      text-align: center;
      background-image: url('../../assets/images/question/question.png');
      background-position: 15px center;
      background-repeat: no-repeat;
    }
  }
}
@media (max-width: 768px) {
  .question-container {
    width: 100%;
    margin-left: 0;
    padding-top: 0;
    margin-top: .2rem;
    .question-item {
      width: 100%;
      padding-bottom: 0.31rem;
      .question-name {
        font-size: 0.28rem;
        line-height: 0.56rem;
        align-items: baseline;
        img {
          width: 0.21rem;
          height: 0.21rem;
          margin-right: 0.22rem;
        }
      }
      .answer {
        font-size: 0.28rem;
        line-height: 0.48rem;
      }
    }
    .hint {
      margin-top: 0.46rem;
      .ques-other {
        height: 1.2rem;
        letter-spacing: 0.02rem;
        padding: 0.2rem 0.3rem 0.2rem 1rem;
        line-height: 1.5;
        border-radius: 0.6rem;
        text-align: left;
        background-position: 0.25rem center;
      }
    }
  }
}
</style>