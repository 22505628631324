<template>
  <div class="main-width-container clearfix">
    <div class="menu">
      <div class="menu-item" v-for="(item,index) in menuData.filter(r=>!r.parent)" :key="index">
        <router-link :to="item.link">{{ item.name }}<img src="../assets/images/more.png" /></router-link>
          <div class="sub-menu" v-if="menuData.filter(r=>r.parent==item.name).length>0">
            <router-link v-for="(subItem,subIndex) in menuData.filter(r=>r.parent==item.name)" :key="subIndex" :to="subItem.link">{{ subItem.name }}</router-link>
         </div>
      </div>
    </div>
    <div class="content-container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  props: {
    menuData: {
      type: Array,
      require: true,
    }
  }
}
</script>

<style lang="less" scoped>
.menu {
  width: 276px;
  height: 280px;
  padding-top: 55px;
  float: left;
  .menu-item a {
    display: block;
    position: relative;
    height: 70px;
    background: #f7f7f7;
    border-bottom: 1px solid #eee;
    color: #333;
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 70px;
    overflow:hidden;
    transition: all .2s;
    
    img {
      width: 8px;
      height: 12px;
      display: none;
      position: absolute;
      top: 28px;
      right: 28px;
    }
    &:hover {
      background: #ecf7ff;
     &::before{
      width: 14px;
     }
      
    }
    &::before{
      content: '';
      display: inline-block;
      width: 0;
      height: 100%;
      background:#cde7ff;
      vertical-align: top;
      margin-right: 30px;
      transition: all .2s;
     
    }
    &.router-link-active {
      background:#5293D2;
      color: #fff;
      &::before{
      background:#85BCF1;
      width: 14px;
     }
      img {
        display: inline-block;
      }
      
    }
  }
  .menu-item:has(.sub-menu)>a{
        border:0 !important;
      }
  .sub-menu{
    background:#f7f7f7;
    display:block;
    // padding-left:44px;
    a{
      border:0;
      background:none !important;
      color:#666666;
      border-bottom:1px solid #eee;
      display:block;
      position: relative;
      overflow: visible;
      font-size:16px;
      padding-left:44px;

      &::before{
        display: none;
        
      }
      &::after{
        content:' ';
        position:absolute;
        bottom:-1px;
        left:0;
        background: #aaa;
        width:0%;
        height:1px;
        transition:all .2s ease-in-out;
      }
      &:hover{
        &::after{
        width:100%;
        }
      }
      &.router-link-active {
        color:#5293D2;
        &::after{
        width:100%;
        background:#5293D2;
        }
      }
    }
    
  }
}
.content-container {
  margin-left: 276px;
}

@media (max-width: 768px) {
  .menu {
    background-image: none;
    overflow: visible;
    padding-bottom:0;
    width: 100%;
    height: auto;
    float: none;
    padding-top: 0.27rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom:2px solid #eee;
    
    .menu-item {
      color: #666;
      font-weight: 600;
      font-size: 0.28rem;
      white-space: nowrap;
      margin-bottom:-3px;
    }

    .menu-item a {
      &::before{
        display:none;
      }
      display: block;
      background: none;
      height: auto;
      color: #666;
      font-size: var(--font-size-l-m);
      letter-spacing: .01rem;
      line-height: 0.6rem;
  
      padding-bottom: 0.1rem;
   
      border-bottom:0;
      
      &.router-link-active {
        background: none;
        color: #3b93d7;

        &::after {
          content: ' ';
          position: absolute;
          height: 4px;
          border-radius: 0.02rem;
          width: 90%;
          left: 5%;
          background: #3b93d7;
          bottom: 0;
        }
      }
    }
  }
  .content-container {
    margin-left: 0;
  }
  .menu {
    .sub-menu{
      display:none;
      position:absolute;
      left:0;
      right:0;
      padding-left:.32rem;
      background:#fff;
      text-align:center;
      margin-top:.2rem;
      overflow-y: scroll;
      a{
        display:inline-block;
        width:30%;
        text-align:center;
        font-weight:normal;
        &.router-link-active{
          &::after{
            display:none;
          }
        }
      }
    } 
    .router-link-active+.sub-menu{
      display:block;
    }
  }
}
</style>  