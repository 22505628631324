<template>
    <div class="page">
      <!-- <div class="banner"></div>  -->
      <div class="main-width-container">
        <div class="text-center">
            <div class="article-title">常见问题</div>
        </div>
        <div class="text-content">
            <div class="text-title">
                <span>通用问题</span>
            </div>
            <div class="sub-text-title">
                <span>1 新系统注册时为什么提示账户已经存在？</span>
            </div>
            <p>答：新系统的注册登录采用了“科创筑梦”的统一账户平台，您可能在参加其他活动时注册过（如创新大赛、影像节等活动）。
若您忘记密码，可通过登陆窗口右下角的忘记密码入口，找回密码，然后再登录。</p>
          
            <div class="sub-text-title">
                <span>2 今年活动的时间安排是怎样的？</span>
            </div>
            <p>答：6-7月，主办单位发布新主题活动指南，各省级组织机构进行活动宣传，组织动员学校网上注册参与活动；<br />
7-9月，各省级组织机构开展教师培训及交流活动，主办单位为学校提供资源包；<br />
7-11月，学校组织学生小组自选活动主题，参照活动指南开展活动；<br />
11月30日前，学生小组、辅导教师提交活动报告，学校推荐优秀科学调查活动小组；<br />
12月31日前，各省级组织机构推荐优秀学校、学生小组、教师活动报告、组织工作者；<br />
2024年2月，公布年度优秀科学调查活动小组、教师活动报告、组织工作者、学校和组织机构名单。</p>
          
            <div class="text-title">
                <span>学生账户</span>
            </div>
            <div class="sub-text-title">
                <span>1 每位参与活动的学生今年是否需要都单独注册账号？</span>
            </div>
            <p>答：和往年活动不同，今年流程有所简化，不再需要学生小组的每位成员都分别单独注册账号。只有创建小组的学生需要注册账号，被添加的组员无需单独注册账号，否则可能无法被添加进小组。<br/>
比如一个小组五个同学，只需其中一位同学进行注册，创建小组。创建小组时，通过【手动添加】填写组员信息，将其他组员加入小组</p>
            <div class="sub-text-title">
                <span>2 为什么学生无法选择该校老师？</span>
            </div>
            <p>答：可能是三种原因导致的：<br />
1.教师尚未在活动平台进行注册。<br />
2.学生和老师注册时选择的学校不一致，需要学生和老师共同确认学校信息。<br /> 
3.教师注册后需经过学校管理员审核，才可被学生选择。（如果学校还没有管理员，学校老师可参照操作指南申请）</p>
<div class="sub-text-title">
                <span>3 每个学生可以参加多少个主题活动？</span>
            </div>
            <p>答：每个小组最多可选择三个主题活动。对于同一个学生，最多同时加入三个不同小组，并且相同主题仅可参与一次，最多参与三个不同主题活动。</p>
            <div class="text-title">
                <span>辅导老师</span>
            </div>
            <div class="sub-text-title">
                <span>1 每个参与活动的老师都需要在活动平台注册账号吗？</span>
            </div>
            <p>答：参与活动的每位辅导教师都需要分别进行注册。</p>
            <div class="sub-text-title">
                <span>2 辅导教师的授权码有什么用？</span>
            </div>
            <p>答：授权码通常用来添加校外老师（当然本校老师使用也没有任何问题）   </p>
            <div class="sub-text-title">
                <span>3 为什么我无法看到生成授权码的入口？</span>
            </div>
            <p>答：出现这种情况是因为您注册后，还没有通过所在学校的管理员审核，您可联系学校管理员帮您审核通过。</p>
            <div class="text-title">
                <span>学校管理员</span>
            </div>
            <div class="sub-text-title">
                <span>1 学校如何申请活动资源包？</span>
              </div>
              <p>答：每所学校可指定一名辅导老师申请成为实施学校（即成为管理员），然后以管理员身份，进入【活动管理】，申请资源包。</p>
              <div class="sub-text-title">
                <span>2 曾经在旧系统上已经申请成为学校管理员的账户，在新活动平台上是否仍然是学校管理员？</span>
              </div>
              <p>答：如果您近3年内（2019、2020、2021）通过旧版活动系统已申请成为学校管理员，在注册填写个人信息时【姓名】、【手机号】、【邮箱】几项请和旧版活动系统中保持一致，在新系统中申请成为学校管理员时，可免于审核。</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    data () {
      return {
      }
    },
    methods: {
   
    }
  }
  </script>
  
  <style scoped lang="less">
  .page {

    width: 890px;
    margin-left: 31px;
    padding-top: 55px;
    padding-bottom: 55px;
    min-width: 890px;
    .main-width-container{
      width: 890px;
      margin: 0 auto;
      .text-content{
        margin-top: 48px;
        img{
            margin-bottom: 24px;
        }
        p{
            font-size: 16px;
            line-height: 32px;
            color: #000;
            margin-bottom: 24px;
            // text-indent: 2em;

        }
        .text-title{
            font-size: 22px;
            font-weight: 700;
            line-height: 30px;
            color: #5293D3;
            position: relative;
            margin-bottom: 24px;
        }
        .sub-text-title{
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: #5293D3;
            margin-bottom: 24px;
        }
        .tip-box{
            width: 100%;
            padding: 10px;
            border-radius: 8px;
            border: 1px solid #2084E1;
            background-color: #EAF5FF;
            margin-bottom: 24px;
            
            
            .tip-title{
                font-size: 16px;
                font-weight: 700;
                line-height: 32px;
                color: #000000;
                display: inline-block;
            }
            .tip-content{
                font-size: 16px;
                line-height: 32px;
                color: #000000;
                display: contents;
            }

        }
        .text-title::after{
            content: '';
            display: block;
            width: 30px;
            height:29px;
            background: url('../../assets//images/guideline/Vector.png') no-repeat;
            background-size: 100% 100%;
            position: absolute;
            top: 0;
            left: 0;
            vertical-align: middle;
        }
        .text-title span{
            margin-left: 34px;
        }
      }
    }
    .article-title {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    line-height: 36px;
    margin-bottom: 24px;
  }
 
 
  }
  




  
  @media (max-width: 768px) {
    .page {
      background: none;
      min-height: 100%;
      width: 100%;
      min-width: 100%;
      padding-top: 1.5rem;
      padding-bottom: 0;
      margin-left: 0;

      .main-width-container{
        width: 100%;
        margin: 0 auto;
        .text-content{
        margin-top:0.48rem;

            img{
            width: 100%;
            }
            p{
            line-height: 1.8;
            color: #000;
        }
       
        }
      
    }
    .article-title {
      font-size: 0.28rem;
      font-weight: 600;
      color: #111111;
      line-height: 0.32rem;
      margin-bottom: 0.24rem;
    

  }
 }
  }

 

  </style>