import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'
import Dynamic from '../views/Dynamic/Dynamic.vue'
import Hdbd from '../views/Dynamic/Hdbd.vue'
import Ndph from '../views/Dynamic/Ndph.vue'
import Tzgg from '../views/Dynamic/Tzgg.vue'
import Guideline from '../views/Guideline/Guideline.vue'
import Participate from '../views/Participate/Participate.vue'
import About from '../views/Participate/About.vue'
import School from '../views/Participate/School.vue'
import Student from '../views/Participate/Student.vue'
import Teacher from '../views/Participate/Teacher.vue'
import Jyfx from '../views/Participate/Jyfx.vue'


import Resource from '../views/Resource/Resource.vue'
import Jskc from '../views/Resource/Jskc.vue'

import YxalJs from '../views/Resource/YxalJs.vue'
import YxalXs from '../views/Resource/YxalXs.vue'


import Zyb from '../views/Resource/Zyb.vue'
import ZybList from '../views/Resource/ZybList.vue'
import Article from '../views//Article.vue'
import Video from '../views/Video.vue'
import Question from '../views/Question/Question'
import QuestionList from '../views/Question/QuestionList.vue'
import ZybVideo from '@/views/Resource/ZybVideo.vue'
import GuidelineCy from '../views/Guideline/Cycx.vue'
import GuidelineAq from '../views/Guideline/Aqjk.vue'
import GuidelineSt from '../views/Guideline/Sthj.vue'
import GuidelineNy from '../views/Guideline/Nyzy.vue'
import GuidelineCzzn from '../views/Guideline/Czzn.vue'
import GuidelineWorkflow from '../views/Guideline/Workflow.vue'
import GuidelineManager from '../views/Guideline/Manager.vue'
import GuidelineCounsellor from '../views/Guideline/Counsellor.vue'
import GuidelineStudentGroup from '../views/Guideline/StudentGroup.vue'
import GuidelineQuestion from '../views/Guideline/Question.vue'
import { MolaTrack } from '../utils/MolaTrack'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      moduleName: '首页',
      title: '青少年科学调查体验活动'
    }
  },
  {
    path: '/home',
    name: '首页',
    component: Home,
    meta: {
      moduleName: '首页',
      title: '青少年科学调查体验活动'
    }
  },
  {
    path: '/article/:id',
    name: '详情',
    component: Article,
    meta: {
      moduleName: '详情页',
      title: '青少年科学调查体验活动'
    }
  },
  {
    path: '/video/:id',
    name: '视频',
    component: Video,
    meta: {
      moduleName: '视频',
      title: '青少年科学调查体验活动'
    }
  },
  {
    path: '/dynamic',
    name: '活动动态',
    component: Dynamic,
    redirect: to => {
      return 'dynamic/tzgg'
    },
    children: [
      {
        path: 'tzgg', component: Tzgg, name: '通知公告',
        meta: {
          moduleName: '活动动态',
          title: '青少年科学调查体验活动'
        }
      },
      {
        path: 'hdbd', component: Hdbd, name: '活动报道',
        meta: {
          moduleName: '活动动态',
          title: '青少年科学调查体验活动'
        }
      },
      {
        path: 'ndph', component: Ndph, name: '年度排行',
        meta: {
          moduleName: '活动动态',
          title: '青少年科学调查体验活动'
        }
      },
    ]
  },
  {
    path: '/guideline',
    name: '活动指南',
    component: Guideline,
    meta: {
      moduleName: '活动指南',
      title: '青少年科学调查体验活动'
    },
    redirect: to => {
      return 'guideline/Nyzy'
    },
    children: [
      {
        path: 'Nyzy', component: GuidelineNy, name: '能源资源',
        meta: {
          moduleName: '活动指南',
          title: '青少年科学调查体验活动'
        },
      },
      {
        path: 'Sthj', component: GuidelineSt, name: '生态环境',
        meta: {
          moduleName: '活动指南',
          title: '青少年科学调查体验活动'
        },
      },
      {
        path: 'Aqjk', component: GuidelineAq, name: '安全健康',
        meta: {
          moduleName: '活动指南',
          title: '青少年科学调查体验活动'
        },
      },
      {
        path: 'Cycx', component: GuidelineCy, name: '创新创意',
        meta: {
          moduleName: '活动指南',
          title: '青少年科学调查体验活动'
        },

      },
      {
        path: 'zyb/list/:id', component: ZybList, name: '相关资源',
        meta: {
          moduleName: '活动指南',
          title: '青少年科学调查体验活动'
        },
      },
      {
        path: 'zyb/list/:id/:fileId', component: ZybVideo, name: '相关资源视频',
        meta: {
          moduleName: '活动指南',
          title: '青少年科学调查体验活动'
        },
      },
    ]
  },

  {
    path: '/participate',
    component: Participate,
    name: '如何参与',
    meta: {
      moduleName: '如何参与',
      title: '青少年科学调查体验活动'
    },
    redirect: to => {
      return 'participate/about'
    },
    children: [
      {
        path: 'join', component: About, name: '如何参与',
        redirect: to => {
          return 'join/student'
        },
      },
      {
        path: 'czzn', component: GuidelineWorkflow, name: '参与指南',
        meta: {
          moduleName: '参与指南',
          title: '青少年科学调查体验活动'
        },
        redirect: to => {
          return 'czzn/workflow'
        }
      },

      {
        path: 'czzn/workflow', component: GuidelineWorkflow, name: '总体流程',
        meta: {
          moduleName: '参与指南',
          title: '青少年科学调查体验活动'
        },
      },
      {
        path: 'czzn/manager', component: GuidelineManager, name: '活动管理员',
        meta: {
          moduleName: '参与指南',
          title: '青少年科学调查体验活动'
        },
      },
      {
        path: 'czzn/counsellor', component: GuidelineCounsellor, name: '辅导教师',
        meta: {
          moduleName: '参与指南',
          title: '青少年科学调查体验活动'
        },
      },
      {
        path: 'czzn/studentGroup', component: GuidelineStudentGroup, name: '学生小组',
        meta: {
          moduleName: '参与指南',
          title: '青少年科学调查体验活动'
        },
      },

      {
        path: 'czzn/question', component: GuidelineQuestion, name: '常见问题',
        meta: {
          moduleName: '参与指南',
          title: '青少年科学调查体验活动'
        },
      },



      {
        path: 'about', component: About, name: '关于活动',
        meta: {
          moduleName: '参与活动',
          title: '青少年科学调查体验活动'
        },
      },
      { path: 'contact', component: GuidelineCzzn ,name:'联系方式', 
        meta: {
          moduleName: '联系方式',
          title:'青少年科学调查体验活动'
        },
      },
      {
        path: 'join/school', component: School, name: '学校',
        meta: {
          moduleName: '参与活动',
          title: '青少年科学调查体验活动'
        },
      },
      {
        path: 'join/student', component: Student, name: '学生',
        meta: {
          moduleName: '参与活动',
          title: '青少年科学调查体验活动'
        },

      },
      {
        path: 'join/teacher', component: Teacher, name: '教师',
        meta: {
          moduleName: '参与活动',
          title: '青少年科学调查体验活动'
        },

      },
      {
        path: 'join/teacher/jyfx', component: Jyfx, name: '经验分享',
        meta: {
          moduleName: '经验分享',
          title: '青少年科学调查体验活动'
        },
      }
    ]
  },
  {
    path: '/question',
    component: Question,
    name: '常见问题',
    redirect: to => {
      return 'question/all'
    },
    children: [
      {
        path: 'all', component: QuestionList, name: '全部常见问题',
        meta: {
          moduleName: '常见问题',
          title: '青少年科学调查体验活动'
        },

      },
      {
        path: 'student', component: QuestionList, name: '学生常见问题',
        meta: {
          moduleName: '常见问题',
          title: '青少年科学调查体验活动'
        },
      },
      {
        path: 'teacher', component: QuestionList, name: '教师常见问题',
        meta: {
          moduleName: '常见问题',
          title: '青少年科学调查体验活动'
        },
      },
      {
        path: 'admin', component: QuestionList, name: '学校常见问题',
        meta: {
          moduleName: '常见问题',
          title: '青少年科学调查体验活动'
        },
      },
    ]
  },
  {
    path: '/resource',
    component: Resource,
    name: '案例资源',
    meta: {
      moduleName: '案例资源',
      title: '青少年科学调查体验活动'
    },
    redirect: to => {
      return 'resource/jskc'
    },
    children: [
      {
        path: 'jskc', component: Jskc, name: '教师课程',
        meta: {
          moduleName: '案例资源',
          title: '青少年科学调查体验活动'
        },
      },
      {
        path: 'yxal', redirect: to => {
          return 'yxal/xs'
        },

      },
      {
        path: 'yxal/xs', component: YxalXs, name: '学生报告',
        meta: {
          moduleName: '案例资源',
          title: '青少年科学调查体验活动'
        },
      },
      {
        path: 'yxal/js', component: YxalJs, name: '教师实践报告',

        meta: {
          moduleName: '案例资源',
          title: '青少年科学调查体验活动'
        },
      },
      {
        path: 'zyb', component: Zyb, name: '资源包',
        meta: {
          moduleName: '案例资源',
          title: '青少年科学调查体验活动'
        },
      },

    ]
  },

]
function setBeforeEnterFunc(routerArray, func) {
  routerArray.forEach(r => {
    r.beforeEnter = func;
    if (r.children) {
      setBeforeEnterFunc(r.children, func);
    }
  });
}
const RouterMap = routes
setBeforeEnterFunc(RouterMap, function (to, from, next) {
  if (to.meta && !to.meta.noTrace) {
    MolaTrack(to.path, location.origin + '/#' + to.path, to.meta.title, to.meta.moduleName, 'dctyhd');
    next();

  }


})



const router = new VueRouter({
  mode: "hash",
  routes
})

export default router
