<template>
    <div class="page">
      <!-- <div class="banner"></div>  -->
      <div class="main-width-container">
        <div class="text-center">
          <div class="article-title">活动总体流程</div>
          <div class="info">
            <p>在活动中，学生可选择一个活动指南作为参考框架，以3-5人的小组为单位，通过观察记录、设计制作、实验验证等学习科学知识和方法，进行科学实践与调查，并通过活动网站提交活动成果。</p>
            <p>教师则指导学生以小组形式，根据兴趣、能力、特长、活动需要，以小组形式围绕某一活动主题开展线下科学调查与科学实践，收集并分析相关数据，最终收获与分享活动成果。在这一过程中, 培养学生的自主参与意识与合作沟通能力。</p>
          </div>
         
        </div>
        <div class="workflow-content">
            <img src="../../assets/images/guideline/work-content.png" alt="">
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    data () {
      return {
      }
    },
    methods: {
    
   
    }
  }
  </script>
  
  <style scoped lang="less">
  .page {

    width: 890px;
    margin-left: 31px;
    padding-top: 55px;
    padding-bottom: 55px;
    min-width: 890px;
    .main-width-container{
      width: 890px;
      margin: 0 auto;
    }
    .workflow-content{
        width: 100%;
        position: relative;
 
        img{
            width: 100%;
        }
    }
 
  }
  


  .article-title {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    line-height: 36px;
    margin-bottom: 48px;
  }
  .info{
    text-align: left;
    p{
        font-size: 16px;
        line-height: 32px;
        font-family: 'Microsoft YaHei';
        color: #000;
        margin-bottom: 24px;
        text-indent: 2em;
    }
  }

  
  @media (max-width: 768px) {
    .page {
      background: none;
      min-height: 100%;
      width: 100%;
      min-width: 100%;
      padding-top: 1.5rem;
      padding-bottom: 0;
      margin-left: 0;

      .main-width-container{
        width: 100%;
        margin: 0 auto;
        .info{
    text-align: left;
    p{
        line-height: 1.8;
        font-family: 'Microsoft YaHei';
        color: #000;
        margin-bottom: 0.24rem;
    }
  }
      }
      .workflow-content{
        width: 100%;
    
      
    }


    }

    .article-title {
      font-size: 0.28rem;
      font-weight: 600;
      color: #111111;
      line-height: 0.32rem;
      margin-bottom: 0.48rem;
    }

  }
  </style>