<template>
  <div class="china-map" ref="chinaMap"></div>
</template>
<script>

import {getMapData,getMapDataNew} from '../../api/api'
let screenWidth = document.documentElement.clientWidth
export default {
  data () {
    return {


      mapData : [],
      provinceData : [],
      mapOptions : {}
      
    }
  },
  mounted () {
    // 获取省份地图数据，得到学生参与数的最大值（用于地图图例）.
     this.getMapInfo();
  },
  methods: {
    // 生成地图配置项
    genMapOptions(mapData){
      const schoolCount = mapData.z_schoolCount;
      const studentCount = mapData.z_studentCount;
      const teacherCount = mapData.z_teacherCount;
      const teamCount = mapData.z_teamCount;
      this.$emit('countChange',{schoolCount,studentCount,teacherCount,teamCount});
      const provinceInfo = mapData.list;

      // 获取学生的最大值和最小值，作为地图图例的最大值和最小值
      let maxStudentCount = 0;
      let minStudentCount = 0;
      provinceInfo.forEach(item => {
        if (item.studentCount > maxStudentCount) {
          maxStudentCount = item.studentCount;
        }
        if (item.studentCount < minStudentCount) {
          minStudentCount = item.studentCount;
        }
      });
      this.provinceData = provinceInfo.map(r=>{return {name:r.provinceName,value:r.studentCount,teamCount:r.teamCount,schoolCount:r.schoolCount,teacherCount:r.teacherCount}})
 
      this.mapOptions = {
          visualMap: {
            min: minStudentCount,
            max: maxStudentCount,
            text: ['高', '低'],
            realtime: false,
            calculable: true,
            inRange: {
              color: ["#B3DB97", "#8BC85F", "#70BF86", "#3CAB95"],
            }
          },
          series: [
            {
              name: '中国地图',
              type: 'map',
              map: 'china',
              label: {
                normal: {
                  show: true,
                  fontSize: screenWidth > 768 ? '18px' : '9px',
                },
              },
              itemStyle: {
                normal: {
                  borderColor: '#515B59',
                  borderWidth: 1
                },
                emphasis: {
                  show: true,
                  color: '#690802',
                  areaColor: '#FFDF55',
                  borderColor: '#fff',
                  borderWidth: 2
                },
              },
              data: this.provinceData,
            },
          ],
        }
      this.chinaEcharts();
      
    },
    chinaEcharts () {
      let chinaMap = this.$echarts.init(this.$refs.chinaMap)
      chinaMap.setOption(this.mapOptions)
      let _this = this;
      // 点击修改省份状态
      chinaMap.on("mouseover", function (params) {
        _this.provinceData.forEach(item => {
          if (params.name == item.name) {
            _this.$emit('countChange',{...item,studentCount:item.value});
          }
        })
      });
      chinaMap.on("mouseout", function () {
        _this.$emit('countChange',{schoolCount:_this.mapData.z_schoolCount,studentCount:_this.mapData.z_studentCount,teacherCount:_this.mapData.z_teacherCount,teamCount:_this.mapData.z_teamCount});
      });
    },

    // 获取地图数据
    async getMapInfo() {
      const res = await getMapDataNew();
      this.genMapOptions(res.data);
      this.mapData = res.data;

      return res;
    }
  }
};
</script>
<style  scoped lang="less">
.china-map {
  width: 818px;
  height: 666px;
}
@media (max-width: 768px) {
  .china-map {
    width: 7.2rem;
    height: 6.5rem;

  }
}
</style>