<template>
  <div class="container">
    <DataList
      v-if="dynamicList"
      :dataList="dynamicList"
      :totalCount="count"
      :hasPagination="true"
      :pageSize="10"
      @handlePageChange="handlePagination"
      showType="CardList"
      showSubType="NewsListItemList"
    ></DataList>
  </div>
  
</template>

<script>
import DataList from '../../components/DataList/DataList.vue'
import {getDynamicList} from '../../api/api'
export default {
  components: {
    DataList
  },
  data () {
    return {
      dynamicList : [],
      type : 1,
      page : 1, 
      size : 10,
      count : null
    }
  },
  created () {
    this.getDynamicData()
  },
  methods: {
    // 通知公告列表
    async getDynamicData() {
      let params = {
        type : this.type,
        page : this.page,
        size : this.size
      }
      const res = await getDynamicList(params)
      this.dynamicList = res.data.list
      this.count = res.data.count
    },
    // 分页
    handlePagination(val) {
      this.page = val
      this.getDynamicData()
    }
  }
}
</script>

<style scoped lang="less">

@media (max-width: 768px) {
  .container {
    width: 100%;
    margin-left: 0;
    padding-top: 0;
  }
}
</style>