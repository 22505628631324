var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"img-box"},[_c('router-link',{attrs:{"to":"/question/admin"}},[_c('img',{attrs:{"src":require("../../assets/images/participate/card-cjwt.png")}})]),_c('router-link',{attrs:{"to":"/guideline"}},[_c('img',{attrs:{"src":require("../../assets/images/participate/card-hdzn.png")}})]),_c('router-link',{attrs:{"to":"/resource/yxal/js"}},[_c('img',{attrs:{"src":require("../../assets/images/participate/card-hdzyb.png")}})])],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"step1"}),_c('div',{staticClass:"title-step"}),_c('div',{staticClass:"focus-entrance"},[_c('div',{on:{"click":_vm.jumpToSchool}},[_c('img',{staticClass:"show-lg",attrs:{"src":require("../../assets/images/participate/xxczzn.png")}}),_c('img',{staticClass:"show-mobile",attrs:{"src":require("../../assets/images/participate/jszn-school-m.png")}})])]),_c('div',{staticClass:"step2"})]),_vm._m(8),_c('table',[_vm._m(9),_vm._l((_vm.schoolList),function(item,index){return _c('tr',{key:index},[_c('td',[(item.rank == 1)?_c('img',{attrs:{"src":require("../../assets/images/participate/1.png")}}):_vm._e(),(item.rank == 2)?_c('img',{attrs:{"src":require("../../assets/images/participate/2.png")}}):_vm._e(),(item.rank == 3)?_c('img',{attrs:{"src":require("../../assets/images/participate/3.png")}}):_vm._e(),(item.rank != 1 && item.rank != 2 && item.rank != 3)?_c('span',[_vm._v(_vm._s(item.rank))]):_vm._e()]),_c('td',[_vm._v(_vm._s(item.unit_name))]),_c('td',[_vm._v(_vm._s(item.city_name))]),_c('td',[_vm._v(_vm._s(item.teacherNumber))]),_c('td',[_vm._v(_vm._s(item.groupNumber))]),_c('td',[_vm._v(_vm._s(item.studentNumber))]),_c('td',[_vm._v(_vm._s(item.groupNumber))])])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('img',{attrs:{"src":require("../../assets/images/participate/icon.png")}}),_vm._v("为什么要以学校名义注册参与活动？")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('p',[_vm._v(" 学校通过活动网站正式注册后就有可能免费获取主办单位提供的活动指南、资源包等活动资源，并推荐优秀学生小组、教师科技实践活动报告参与全国评选，同时参与全国优秀活动实施学校的评选。学校还可以提交相关活动信息和成果，并通过活动网站进行展示。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('img',{attrs:{"src":require("../../assets/images/participate/icon.png")}}),_vm._v("学校如何注册？")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('p',[_vm._v(" 选择一名教师作为学校管理员，通过活动网站提交个人及学校信息，并在生成的申请表上盖章上传到活动网站即注册成功。活动小组的辅导教师与学校管理员可以为同一人。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('img',{attrs:{"src":require("../../assets/images/participate/icon.png")}}),_vm._v("学校注册有时间限制吗？")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('p',[_vm._v(" 学校任何时间均可注册，没有时间限制。学校注册后即可参与当年的全国评选。学校第一次注册提交盖章申请表后，如第二年再次参与活动评选，通过学校管理员账号登录申请活动实施学校，不需要再次提交申请表。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('img',{attrs:{"src":require("../../assets/images/participate/icon.png")}}),_vm._v("优秀活动实施学校的评选标准是什么？")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('p',[_vm._v("评选全国优秀活动实施学校时重点考察学校参与学生数量，通过网站提交的调查数据和活动成果的情况等。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"school-rank text-center"},[_c('div',[_vm._v("学校排名 (按照参与小组数由高到低)")]),_c('div',{staticClass:"show-mobile desc-to-pc"},[_vm._v("PC端访问查看完整榜单")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("排名")]),_c('th',[_vm._v("学校")]),_c('th',[_vm._v("地区")]),_c('th',[_vm._v("教师")]),_c('th',[_vm._v("小组")]),_c('th',[_vm._v("学生")]),_c('th',[_vm._v("报告")])])
}]

export { render, staticRenderFns }