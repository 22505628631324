<template>
  <div class="container">
      <div class="img-box">
        <router-link to="/question/student">
        <img src="../../assets/images/participate/card-cjwt.png"/>
        </router-link>
        <router-link to="/guideline">
        <img src="../../assets/images/participate/card-hdzn.png"/>
        </router-link>
        <router-link to="/resource/yxal/xs">
        <img src="../../assets/images/participate/card-hdzyb.png" />
        </router-link>
      </div>
      <div class="name"><img src="../../assets/images/participate/icon.png" />参与资格是什么</div>
      <div class="info">
        <p>全国各地小学、初中学生均可参与活动。</p>
      </div>

      <div class="name"><img src="../../assets/images/participate/icon.png" />学生如何参与</div>
      <div class="info">
        <p>
          建立3-5人的小组，通过活动网站注册，选择活动主题，下载活动资源，即可参与活动。可以以学生小组的名义参与活动，也可以向学校提出申请，以学校的名义参与活动并免费获取活动指南、资源包等有关资源。
        </p>
      </div>

      <div class="name"><img src="../../assets/images/participate/icon.png" />活动开展形式是什么？</div>
      <div class="info">
        <p>
          参与学生使用下载的活动资源，根据活动指南内容在辅导教师指导下开展调查实践活动，同时可以通过网站上传调查数据和调查报告，并与班级其他同学分享研究成果，或通过社交媒体平台进行展示。
        </p>
      </div>

      <div class="name"><img src="../../assets/images/participate/icon.png" />活动评价机制是什么？</div>
      <div class="info">
        <p>
          在活动网站正式注册的学校在年度评选时具有推荐优秀学生小组的资格，推荐数量根据学校参与学生人数及线上调查数据和报告提交情况等确定。主办单位将向选出的优秀学生小组发放证书及活动纪念品。推荐学生小组时应以过程性评价为主，鼓励学生自我评价与同伴间的合作交流和经验分享，将学生在综合实践活动中的各种表现和活动成果作为重要依据，对学生的活动过程和结果进行综合评价。
        </p>
      </div>
      <div class="text-center">
        <div class="step1"></div>
        <div class="focus-entrance">
          <div @click="jumpToStudent">
            <img src="../../assets/images/participate/czzn.png" class="show-lg"/>
            <img src="../../assets/images/participate/czzn-m.png" class="show-mobile"/>
          </div>
        </div>
        <div class="step2"></div>
      </div>
    
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {
    jumpToStudent(){
      this.$router.push('/article/1094')
    }
  }
}
</script>

<style scoped lang="less">
.container {  
    .text-center {
      .step1 {
        width: 100%;
        height: 128px;
        background: url('../../assets/images/participate/step-1.png') no-repeat center;
        background-size: 100% 100%;
      }
      .focus-entrance {
        text-align: center;
        margin: 50px auto;
        cursor: pointer;
        img {
          max-width: 100%;
          margin: 0 auto;
        }
      }
      .step2 {
        width: 100%;
        height: 934px;
        background: url('../../assets/images/participate/step-2.png') no-repeat center;
        background-size: 100% 100%;
      }
    }
  
}
@media (max-width: 768px) {
  .container {  
      .text-center {
        .step1 {
          width: 6.85rem;
          height: 3.62rem;
          background: url('../../assets/images/participate/step-student-m.png') no-repeat center;
          background-size: 100% 100%;
        }
        .focus-entrance {
          width: 100%;
          margin: .8rem auto;
        }
        .step2 {
          width: 100%;
          height: 12.37rem;
          background: url('../../assets/images/participate/step-2-student-m.png') no-repeat center;
          background-size: 100% 100%;
        }
      }
    
  }
}
</style>