<template>
  <div class="page-with-menu">
    <div class="condition">
      <el-select v-model="yearVal" placeholder="请选择年份" clearable @change="selectChange">
        <el-option v-for="(item,index) in yearOption" :key="index" :label="item" :value="item"> </el-option>
      </el-select>
      <el-select v-model="provinceVal" placeholder="请选择省份" clearable @change="selectChange">
        <el-option v-for="(item,index) in provinceOption" :key="index" :label="item" :value="item">
        </el-option>
      </el-select>
      <el-select v-model="subjectVal" placeholder="请选择主题" clearable @change="selectChange">
        <el-option v-for="(item,index) in subjectOption" :key="index" :label="item.title" :value="item.id"> </el-option>
      </el-select>
      <el-input v-model="keyword" placeholder="请输入关键字">
        <el-button slot="append" icon="el-icon-search" @click="searchClick"></el-button>
      </el-input>
    </div>

    <DataList
      class="card-list"
      v-if="caseData"
      :dataList="caseData"
      :totalCount="total"
      :hasPagination="true"
      showType="CardList"
      showSubType="CardItemCase"
      @handlePageChange="handlePagination"
    ></DataList>
  </div>
</template>

<script>
let pageSize = 9;
import DataList from '@/components/DataList/DataList.vue'
import { getYearData, getProvinceData, getThemeData,getCaseData } from '../../api/api'
export default {
  components: {
    DataList
  },
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  mounted () {
    this.fetchData()
  },
  data () {
    return {
      yearVal: null,
      provinceVal: null,
      subjectVal: null,
      keyword: null,
      total:0,
      // sampleData.当前默认为教师，学生与教师的数据结构区别是，学生多一个“指导”栏位数据。
      caseData: [

      ],
      yearOption: [],

      provinceOption: [],
      subjectOption: []
    }
  },
  methods: {
    searchClick () {
      this.fetchData()
    },
    fetchData () {
      let params={
        type:this.type,
        size:9,
        page:this.page,
        year:this.yearVal==='全部'?null:this.yearVal,
        province:this.provinceVal,
        themeId:this.subjectVal==='全部'?null:this.subjectVal,
        title:this.keyword?this.keyword:null
      }
      getCaseData(params).then(res => {
        this.total=res.data.count
        this.caseData = res.data.list
      })
      // this.caseData = [
      //   { id: 1, title: '屋顶绿化植物对于温度的影响研究', author: '张三', province: '江苏省', city: '南京市', school: '昆山高新区西塘实验小学', subject: '我的低碳生活', publishTime: '2019-01-01', extLink: 'http://www.baidu.com' },
      //   { id: 2, title: '屋顶绿化植物对于温度的影响研究', author: '张三', province: '江苏省', city: '南京市', school: '昆山高新区西塘实验小学', subject: '我的低碳生活', publishTime: '2018-01-01', extLink: 'http://www.baidu.com' },
      //   { id: 3, title: '屋顶绿化植物对于温度的影响研究', author: '张三', province: '江苏省', city: '南京市', school: '昆山高新区西塘实验小学', subject: '我的低碳生活', publishTime: '2019-01-01', extLink: 'http://www.baidu.com' },
      //   { id: 4, title: '屋顶绿化植物对于温度的影响研究', author: '张三', province: '江苏省', city: '南京市', school: '昆山高新区西塘实验小学', subject: '我的低碳生活', publishTime: '2019-01-01', extLink: 'http://www.baidu.com' },
      //   { id: 5, title: '屋顶绿化植物对于温度的影响研究', author: '张三', province: '江苏省', city: '南京市', school: '昆山高新区西塘实验小学', subject: '节约粮食，从我做起', publishTime: '2019-01-01', extLink: 'http://www.baidu.com' },
      //   { id: 6, title: '屋顶绿化植物对于温度的影响研究', author: '张三', province: '江苏省', city: '南京市', school: '昆山高新区西塘实验小学', subject: '我的低碳生活', publishTime: '2019-01-01', extLink: 'http://www.baidu.com' },
      //   { id: 7, title: '屋顶绿化植物对于温度的影响研究', author: '张三', province: '江苏省', city: '南京市', school: '昆山高新区西塘实验小学', subject: '节约粮食，从我做起', publishTime: '2019-01-01', extLink: 'http://www.baidu.com' },
      //   { id: 8, title: '葡萄园保留良性杂草对葡萄园生态环境的影响研究', author: '张三', province: '江苏省', city: '南京市', school: '昆山高新区西塘实验小学', subject: '我的低碳生活', publishTime: '2019-01-01', extLink: 'http://www.baidu.com' },
      //   { id: 9, title: '屋顶绿化植物对于温度的影响研究', author: '张三', province: '江苏省', city: '南京市', school: '昆山高新区西塘实验小学', subject: '节约粮食，从我做起', publishTime: '2019-01-01', extLink: 'http://www.baidu.com' },
      // ]
      // if (this.type != 'teacher') {
      //   this.caseData = this.caseData.map(item => {
      //     item.teacher = '王二麻子,李四'
      //     return item
      //   })

      // }
    },

    // 获取年份列表
    async getYearList () {
      const res = await getYearData();
      this.yearOption = ['全部',...res.data];
      
    },
    // 获取省份列表
    async getProvinceList () {
      const res = await getProvinceData();
      this.provinceOption = ['全部',...res.data];
      
    },
    //获取省份列表
    async getProvinceList () {
      const res = await getProvinceData()
      this.provinceOption = res.data
    },
   // 获取主题列表
   async getSubjectList () {
      const res = await getThemeData();
      this.subjectOption = [{id:0,title:'全部'},...res.data];
    },
    handlePagination (val) {
      this.page = val
      this.fetchData()
    },
    selectChange(){
      this.fetchData()
    
    }
  },
  created () {
    this.getYearList()
    this.getProvinceList()
    this.getSubjectList();

   
  },

}
</script>

<style lang="less" scoped>
.page-with-menu {
  padding-top: 55px;
  padding-left: 30px;
}
.condition {
  display: flex;
  gap: 20px;
  & > * {
    width: 25%;
  }
}
.card-list {
  margin-top: 20px;
}
/deep/.el-input__suffix {
  right: 0px;
  width: 37px;
  background: var(--main-color-1);
  color: #fff;
}
/deep/.el-input-group__append {
  background: var(--main-color-1);
  color: #fff;
  border-radius: 0;
  padding: 0 12px;
}
/deep/.el-select .el-input .el-select__caret {
  color: #fff;
  font-size: 16px;
}

@media (max-width: 798px) {
    .page-with-menu {
      padding-top: .3rem;
      padding-left:0;
    }
    
  .condition {
    margin-top:.6rem;
  display: block;
  & > * {
    width: 100%;
  }
}
.card-list {
  margin-top: .4rem;
}
/deep/.el-input{
  margin-bottom:.2rem;
}
/deep/.el-input__suffix {
  right: 0;
  width: .7rem;
  background: var(--main-color-1);
  color: #fff;
}
/deep/.el-input-group__append {
  background: var(--main-color-1);
  color: #fff;
  border-radius: 0;
  padding: 0 0;
  width:.7rem;
  text-align:center;
  font-size: .32rem;
}
/deep/.el-select .el-input .el-select__caret {
  color: #fff;
  font-size: .32rem;
}
  }


</style>