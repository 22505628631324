<template>
    <router-link :to="cardData.link" class="news-list-item">
            <div class="news-title">
              {{ cardData.title }}
            </div>
            <div class="publish-date">
              <span v-if="cardData.publishTime">{{ cardData.publishTime.slice(0, 10) }}</span>
            </div>
          </router-link>
</template>

<script>
export default {
  name: 'NewsListItemHome',
  props: {
    cardData: {
      type: Object,
      require: true
    },
  }
}
</script>

<style scoped lang="less">
.news-list-item{
    float:left;
    position:relative;
    width:31%;
    margin-right:3%;
    padding:20px 0;
    margin-bottom:25px;
    box-sizing:border-box;
    height:140px;
    border-bottom:2px solid #ccc;
    &:nth-child(3n){
      margin-right:0;
    }
    &::after{
      content: ' ';
      position:absolute;
      bottom:-2px;
      left:0;
      right:0;
      height: 2px;
      background-color: #5293D2;
      width:0%;
      transition:all .2s ease-in-out;
      
    }
    &:hover{
      &::after{
        width:100%;
      }
    }
    .news-title{
      color:#333;
      font-size:18px;
      line-height:1.8;
      height:60px;
      overflow:hidden;
    }
    .publish-date{
      font-size:16px;
      color:#999;
      position:absolute;
      left:0;
      bottom:10px;
    }
  }
@media (max-width: 768px) {
  .news-list-item{
    float:none;
    display:block;
    width:100%;
    margin-right:0;
    padding:.4rem 0;
    margin-bottom:.4rem;
    height:2.6rem;
    border-bottom:2px solid #eee;

    .news-title{
      font-size:.32rem;
      height:auto;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow:hidden;
    }
    .publish-date{
      font-size:.28rem;
      left:inherit;
      right:0;
      bottom:.16rem;
    }
  }
}
</style>