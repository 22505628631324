<template>
  <div class="page">
    <div class="banner"></div>     
    <Menu :menuData="menuData" ></Menu> 
  </div>
</template>

<script>
import Menu from '../../components/Menu.vue'

export default {
  components: {
    Menu
  },
  data () {
    return {
      menuData: [
        { link: '/guideline', name: '活动指南'},
        { link: '/guideline/Nyzy', name: '能源资源',parent:'活动指南',id:1},
        { link: '/guideline/Sthj', name: '生态环境',parent:'活动指南',id:2},
        {link:'/guideline/Aqjk',name:'安全健康',parent:'活动指南',id:3},
        {link:'/guideline/Cycx',name:'创新创意',parent:'活动指南',id:4},
      ],
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.page {
  background: url('../../assets/images/guideline/bg.png') no-repeat center;
  background-size: 100% 100%;
  min-height:1000px;
}
.banner {
  height: 400px;
  width: 100%;
  background: url('../../assets/images/guideline/banner.jpg') no-repeat center;
  background-size: 1920px 400px;
}



@media (max-width: 768px) {
  .page {
    background: none;
  }
  .banner {
    height: 2.68rem;
    background: url('../../assets/images/dynamic/banner-hd-m.jpg') no-repeat center;
    background-size: 100% 100%;
  }
 

}
</style>