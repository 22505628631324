<template>
  <Yxal :type="1"></Yxal>
</template>

<script>

import DataList from '@/components/DataList/DataList.vue';
import Yxal from './Yxal.vue';
export default {
  components: {
    DataList,Yxal
  },
  data(){
    return {

    }
  },

}
</script>

<style lang="less" scoped>
</style>