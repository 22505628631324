<template>
  <div class="container">
    <el-tabs v-model="activeName" class="rank-tab-container" @tab-click="handleClick">
      <el-tab-pane label="省级参与排行" name="province">
        <DataList
          v-if="provinceHeadNameList"
          :headNameList="provinceHeadNameList"
          :dataList="provinceList"
          :totalCount="provinceList.length"
          :hasPagination="false"
          showType="Table"
        ></DataList>
      </el-tab-pane>
      <el-tab-pane label="地市参与排行" name="city">
        <DataList
          v-if="cityHeadNameList"
          :headNameList="cityHeadNameList"
          :dataList="cityList"
          :totalCount="cityCount"
          :hasPagination="true"
          :pageSize="size"
          @handlePageChange="handleCityPagination"
          showType="Table"
        ></DataList>
      </el-tab-pane>
      <el-tab-pane label="学校参与排行" name="school">
        <DataList
          v-if="schoolHeadNameList"
          :headNameList="schoolHeadNameList"
          :dataList="schoolList"
          :totalCount="schoolCount"
          :hasPagination="true"
          :pageSize="size"
          @handlePageChange="handleSchoolPagination"
          showType="Table"
        ></DataList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import DataList from '../../components/DataList/DataList'
import { getProvinceRank, getCityRank, getSchoolRank } from '../../api/api'
export default {
  components: {
    DataList
  },
  data () {
    return {
      activeName: 'province',
      cityPage: 1,
      schoolPage: 1,
      size: 20,
      cityHeadNameList: [
        '排名', '参与省份','参与地区', '参与学校','参与学生', '参与小组', '参与教师'
      ],
      provinceHeadNameList: [
      '排名', '参与地区', '参与学校','参与学生', '参与小组', '参与教师'
      ],
      schoolHeadNameList: [
      '排名', '学校名称', '参与学生', '参与小组', '参与教师'
      ],
      cityList: [],
      provinceList: [],
      schoolList: [],
      cityCount: null,
      schoolCount: null,
    }
  },
  mounted () {
    // 初始化获取省级排行
    this.getProvince()
  },
  methods: {
    // 获取排行列表
    async handleClick (tab) {
      if (tab.index == 0) {
        this.getProvince()
      } else if (tab.index == 1) {
        this.getCity()
      } else if (tab.index == 2) {
        this.getSchool()
      }
    },
    // 省级列表
    getProvince () {
      getProvinceRank().then((res) => {
        this.provinceList = res.data
        for (let i = this.provinceList.length - 1; i >= 0; i--) {
        // 如果provinceName省份包含台湾，将名字改为台湾省
          if (this.provinceList[i].provinceName.indexOf('台湾') != -1) {
            this.provinceList[i].provinceName = '台湾省'
          }
        // 根据参与学校schoolNumber数量排序
        this.provinceList.sort((a, b) => {
          return b.schoolNumber - a.schoolNumber
        })
          if (this.provinceList[i].groupNumber == '0' && this.provinceList[i].studentNumber == '0' && this.provinceList[i].teacherNumber == '0' && this.provinceList[i].schoolNumber == '0') {
            
            this.provinceList.push(this.provinceList[i])
            this.provinceList.splice(i, 1)
          }
        }
        //如果包含台湾、香港、澳门，则不显示
        this.provinceList = this.provinceList.filter(item => {
          return item.provinceName.indexOf('台湾') == -1 && item.provinceName.indexOf('香港') == -1 && item.provinceName.indexOf('澳门') == -1
        })
     
       
   
        // 添加排名
        this.provinceList.forEach((item, index) => {
          item.rank = index + 1
          item.rankName = 'province'
        })
      })
    },
    // 地市列表
    async getCity () {
      let params = {
        page: this.cityPage,
        size: this.size
      }
      const res = await getCityRank(params)


         
      //如果包含台湾、香港、澳门，则不显示
      res.data.list = res.data.list.filter(item => {
          return item.provinceName.indexOf('台湾') == -1 && item.provinceName.indexOf('香港') == -1 && item.provinceName.indexOf('澳门') == -1
        })
     
      this.cityList = res.data.list
      // 排名
      this.cityList.forEach((item, index) => {
        item.rank = index + (this.cityPage - 1) * this.size + 1
        item.rankName = 'city'
      })
      this.cityCount = res.data.count
    },

    // 学校列表
    async getSchool () {
      let params = {
        page: this.schoolPage,
        size: this.size
      }
      const res = await getSchoolRank(params)
      
      this.schoolList = res.data.list
       //如果包含台湾、香港、澳门，则不显示
       res.data.list = res.data.list.filter(item => {
          return item.provinceName.indexOf('台湾') == -1 && item.provinceName.indexOf('香港') == -1 && item.provinceName.indexOf('澳门') == -1
        })
      // 排名
      this.schoolList.forEach((item, index) => {
        item.rank = index + (this.schoolPage - 1) * this.size + 1
        item.rankName = 'school'
      })
  
       
      this.schoolCount = res.data.count
    },
    // 地市排行分页
    handleCityPagination (val) {
      this.cityPage = val
      this.getCity()
    },
    // 学校排行分页
    handleSchoolPagination (val) {
      this.schoolPage = val
      this.getSchool()
    }
  }
};
</script>

<style scoped lang="less">
.container {
  width: 890px;
  margin-left: 31px;
  padding-top: 55px;
  /deep/.el-tabs__item.is-active {
    
    // border-bottom:4px solid #5293D2;
    // &::after {
    //   content: '';
    //   position: absolute;
    //   bottom: 0;
    //   left: 50%;
    //   width: 10px;
    //   transform:translateX(-50%);
    //   height: 12px;
    //   border:6px solid transparent;
    //   border-bottom:6px solid #5293d2;
    // }
  }
  /deep/.el-tabs__active-bar {
    height:4px;
    background-color:#5293d2;
    &::after {
      content: '';
      position: absolute;
      bottom: 3px;
      left: 50%;
      width: 10px;
      transform:translateX(-50%);
      height: 12px;
      border:6px solid transparent;
      border-bottom:6px solid #5293d2;
    }
  }
  /deep/.el-tabs__item {
    padding: 0 20px;

   
    
    font-size: 16px;
    color: #333333;
    line-height: 32px;
    height:42px;
    
  }
  /deep/.el-tabs__content {
    padding-bottom: 150px;
  }
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    margin-left: 0;
    padding-top: 0;
    padding-bottom:0;
    /deep/.el-tabs__nav{
      width:100%;
    }
    /deep/.el-tabs__item.is-active {
     
    }
    /deep/.el-tabs__active-bar {
      
    }
    /deep/.el-tabs__nav{
      white-space: nowrap;
    }
    /deep/.el-tabs__header {
      margin: 0rem;
      z-index: 1;
    }
    /deep/.el-tabs__item {
      padding: 0 .45rem;
      color: #666;
      height: 0.63rem;
      font-size: 0.28rem;
      line-height: 0.5rem;
      
      text-align:center;
    }
    /deep/.el-tabs__content {
      padding-bottom: 0;
    }
  }
  .rank-tab-container{
    margin-top:.5rem;
  }
}
</style>