var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"img-box"},[_c('router-link',{attrs:{"to":"/question/student"}},[_c('img',{attrs:{"src":require("../../assets/images/participate/card-cjwt.png")}})]),_c('router-link',{attrs:{"to":"/guideline"}},[_c('img',{attrs:{"src":require("../../assets/images/participate/card-hdzn.png")}})]),_c('router-link',{attrs:{"to":"/resource/yxal/xs"}},[_c('img',{attrs:{"src":require("../../assets/images/participate/card-hdzyb.png")}})])],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"step1"}),_c('div',{staticClass:"focus-entrance"},[_c('div',{on:{"click":_vm.jumpToStudent}},[_c('img',{staticClass:"show-lg",attrs:{"src":require("../../assets/images/participate/czzn.png")}}),_c('img',{staticClass:"show-mobile",attrs:{"src":require("../../assets/images/participate/czzn-m.png")}})])]),_c('div',{staticClass:"step2"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('img',{attrs:{"src":require("../../assets/images/participate/icon.png")}}),_vm._v("参与资格是什么")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('p',[_vm._v("全国各地小学、初中学生均可参与活动。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('img',{attrs:{"src":require("../../assets/images/participate/icon.png")}}),_vm._v("学生如何参与")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('p',[_vm._v(" 建立3-5人的小组，通过活动网站注册，选择活动主题，下载活动资源，即可参与活动。可以以学生小组的名义参与活动，也可以向学校提出申请，以学校的名义参与活动并免费获取活动指南、资源包等有关资源。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('img',{attrs:{"src":require("../../assets/images/participate/icon.png")}}),_vm._v("活动开展形式是什么？")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('p',[_vm._v(" 参与学生使用下载的活动资源，根据活动指南内容在辅导教师指导下开展调查实践活动，同时可以通过网站上传调查数据和调查报告，并与班级其他同学分享研究成果，或通过社交媒体平台进行展示。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name"},[_c('img',{attrs:{"src":require("../../assets/images/participate/icon.png")}}),_vm._v("活动评价机制是什么？")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('p',[_vm._v(" 在活动网站正式注册的学校在年度评选时具有推荐优秀学生小组的资格，推荐数量根据学校参与学生人数及线上调查数据和报告提交情况等确定。主办单位将向选出的优秀学生小组发放证书及活动纪念品。推荐学生小组时应以过程性评价为主，鼓励学生自我评价与同伴间的合作交流和经验分享，将学生在综合实践活动中的各种表现和活动成果作为重要依据，对学生的活动过程和结果进行综合评价。 ")])])
}]

export { render, staticRenderFns }