<template>
  <div class="page-with-menu ">

    <DataList
      class="card-list"
      v-if="cardListData"
      :dataList="cardListData"
      :totalCount="count"
      :hasPagination="true"
      showType="CardList"
      :showView="false"
      :pageSize="size"
      showSubType="CardItemKc"
      @handlePageChange="handlePageChange"
    
    ></DataList>
  </div>

</template>

<script>
import DataList from '@/components/DataList/DataList.vue';
import {getTeacherCourseData} from '../../api/api'
export default {
  components: {
    DataList
  },
  data(){
    return {
      cardListData:[],
      count: null,
      page: 1,
      size: 12
    }
  },
  created () {
    this.getTeacherList()
  },
  methods: {
    // 获取教师课程列表
    async getTeacherList() {
      const res = await getTeacherCourseData(this.page, this.size)
      this.cardListData = res.data.list
      // 截取年月日
      this.cardListData.forEach(item => {
        item.startTime = item.startTime.slice(0,10)
        item.endTime = item.endTime.slice(0,10)
      })
      this.count = res.data.count
    },
    // 分页
    handlePageChange(val) {
      this.page = val
      this.getTeacherList()
    }
  }
}
</script>

<style scoped lang="less">
  .page-with-menu {
    padding-top: 55px;
    padding-left:30px;
  }

  @media (max-width: 798px) {
    .page-with-menu {
      padding-top: .3rem;
      padding-left:0;
    }
  }

</style>