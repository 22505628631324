<template>
  <router-link :to="cardData.link" class="card">
          <div class="image-container">
            <img :src="cardData.image"/>
          </div>
          <div class="publish-date">
            <div class="date">{{ getDateOnly(cardData.publishTime) }}</div>
            <div class="year-month">{{getYearMonth(cardData.publishTime)}}</div>
          </div>
          <div class="news-title">
            {{ cardData.title }}
          </div>
          <div class="widget-corner">
            <div class="piece p-1"></div>
            <div class="piece p-2"></div>
            <div class="piece p-3"></div>
            <div class="piece p-4"></div>
            <div class="piece p-5"></div>
            <div class="piece p-6"></div>
          </div>
        </router-link>

</template>

<script>
export default {
  name: 'cardListItemHome',
  props: {
    cardData: {
      type: Object,
      require: true
    },
    showDate: {
      type: Boolean,
      default: true
    },
    showView:{
      type:Boolean,
      default:true
    },
    // 卡片对应的类型。如下：article,photo,video
    contentType:{
      type:String,
      default:''
    }

  },
  data () {
    return {
      
    }
  },
  methods: {
    getDateOnly (dateString) {
      let date = new Date(dateString.replace(' ','T'));
      return date.getDate();
    },
    getYearMonth(dateString){
      let date = new Date(dateString.replace(' ','T'));
      return date.getFullYear() + '.' + (date.getMonth() + 1);
    }
  }
}
</script>

<style scoped lang="less">

  .card{
    position: relative;
    width: 370px;
    height: 430px;
    background: #FFFFFF;
    border: 0px solid #D0D3D5;
    box-shadow:0 7px 24px 0 rgba(135, 135, 135, 0.17);
    box-sizing:border-box;
    padding:20px;
    border-bottom:2px solid rgba(135, 135, 135, 0.17);
    transition:all 0.2s ease-out;
    &:hover{
      transform:translate3d(0,-15px,0);
      border-bottom:2px solid #5293D2;
     
    }
    img{
      display:block;
      width:100%;
      height:185px;
    }
    .publish-date{
      text-align:center;
      font-family: NewYork;
      margin-left:-10px;
      float:left;
      width:60px;
      &::before{
        content: '';
        display: block;
        width: 1px;
        height: 100px;
        background: #aaa;
        margin: 35px auto 10px auto;
      }
      .date{
        font-size:36px;
        line-height:36px;
      }
      .year-month{
        font-size:12px;
      }
    }
    .news-title{
      margin-top:30px;
      font-size:18px;
      line-height:1.8;
    }

    .widget-corner{
      position: absolute;
      bottom:25px;
      right:6px;
      .piece{
        width: 6px;
        height: 6px;
        background: #ccc;
        position: absolute;
        transition:all .05s ease-out;

        &.p-1{
          top:0;
          right:0;
          background:#EBEBEB;
          transition-delay:0;
        }
        &.p-2{
          top:7px;
          right:0;
          background:#E1E1E1;
          transition-delay:0.05s;
        }
        &.p-3{
          top:14px;
          right:0;
          background:#ccc;
          transition-delay:0.1s;
          
        }
        &.p-4{
          top:7px;
          right:7px;
          background:#ccc;
          transition-delay:0;
          transition-delay:0.05s;
        }
        &.p-5{
          top:14px;
          right:7px;
          background:#E1E1E1;
          
        }
        &.p-6{
          top:14px;
          right:14px;
          background:#EBEBEB;
          transition-delay:0;
        }
      }
    }
    &:hover{
      .widget-corner{
        .piece{
          &.p-1{
         
            background: #CA86A4;
            transition-delay:0.1s;
          }
          &.p-2{
            background: #8B4686;
            transition-delay:0.05s;
          }
          &.p-3{
            background: #0F549A;
            transition-delay:0;
          }
          &.p-4{
            background: #D0251D;
            transition-delay:0.1s;
          }
          &.p-5{
            background: #A1B943;
            transition-delay:0.05s;
          }
          &.p-6{
            background: #D8AD26;
            transition-delay:0.1s;
          }
        }
      }
    }
  }


@media (max-width: 768px) {
  .card{
    display:block;
    width: 100%;
    margin-bottom: .5rem;
    height: 8rem;
    padding:.35rem;
    &:hover{
      transform:none; 
    }
    img{
      height:3.44rem;
    }
    .news-title{
      margin-top:.5rem;
      font-size:.32rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;

    }
    .publish-date{
      text-align:center;
      font-family: NewYork;
      margin-left:-10px;
      float:left;
      width:60px;
      &::before{
        content: '';
        display: block;
        width: 1px;
        height: 2rem;
        background: #aaa;
        margin: 0.7rem auto 0.2rem auto;
      }
      .date{
        font-size:0.72rem;
        line-height:0.72rem;
      }
      .year-month{
        font-size:0.24rem;
      }
    }
  }


}
</style>