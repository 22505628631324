<template>
    <router-link :to="cardData.link" class="news-list-item">
            <div class="news-title">
              {{ cardData.title }}
            </div>
            <div class="publish-date">
              <span v-if="cardData.publishTime">{{ cardData.publishTime.slice(0, 10) }}</span>
            </div>
          </router-link>
</template>

<script>
export default {
  props: {
    cardData: {
      type: Object,
      require: true
    },
  }
}
</script>

<style scoped lang="less">
.news-list-item{
    
    position:relative;
    display:block;
    
    padding:0 0 30px 30px;
    margin-bottom:25px;
    box-sizing:border-box;
   
    border-bottom:2px solid #eee;
     &:before{
      content: ' ';
      position:absolute;
      top:12px;
      left:0;
      border-radius:50%;
      width: 10px;
      height: 10px;
      background-color: #ccc;
    }
    &::after{
      content: ' ';
      position:absolute;
      bottom:-2px;
      left:0;
      right:0;
      height: 2px;
      background-color: #5293D2;
      width:0%;
      transition:all .2s ease-in-out;
      
    }
    &:hover{
      &::after{
        width:100%;
      }
      &:before{
        background-color: #5293D2;
      }
    }
    .news-title{
      color:#333;
      font-size:18px;
      line-height:1.8;

    }
    .publish-date{
      font-size:14px;
      color:#999;
      position:absolute;
      right:0;
      bottom:10px;
      background:url("../../assets/images/time.jpg") no-repeat left center;
      background-size:15px 15px;
      padding-left:20px;
    }
  }



@media (max-width: 768px) {
  .news-list-item{
    padding:0 0 .65rem .3rem;
    margin-bottom:0rem;
    margin-top:.3rem;
     &:before{
      top:.18rem;
      width: .16rem;
      height: .16rem;
      background-color: #5293D2;
    }
    .news-title{
      font-size:.28rem;
    }
    .publish-date{
      font-size:.24rem;

      bottom:.1rem;
      
      background-size:.22rem .22rem;
      padding-left:.3rem;
    }
  }
}
</style>