<template>
  <div class="container">
    <div :class="'list ' + (showDate ? 'has-date' : '')" v-if="dataList.length > 0">
      <div class="item-container" v-for="item in dataList" :key="item.id">
        <div class="item">
          <router-link :to="item.link" class="more" target="_blank">{{ item.title }}</router-link>
        </div>
        <div class="date">
          <img src="../../assets/images/time.jpg" alt="" />
          <span v-if="showDate && item.publishTime">{{ item.publishTime.slice(0, 10) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextList',
  props: {
    dataList: {
      type: Array,
      require: true
    },
    showDate: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      pageWidth: window.innerWidth
    }
  },
}
</script>

<style scoped lang="less">
.list {
  .item-container {
    position: relative;
    padding-bottom: 70px;
    border-bottom: 1px solid #eee;
    padding-top: 20px;
    &:nth-child(1) {
      padding-top: 0;
    }
    &:first-child::before {
      top: 8px;
    }
    &::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background: #cccccc;
      border-radius: 50%;
      position: absolute;
      top: 28px;
      left: 0px;
    }
    .date {
      color: #666;
      font-weight: 400;
      font-size: 14px;
      position: absolute;
      right: 0;
      bottom: 18px;
      display: flex;
      align-items: center;
    }
    img {
      width: 15px;
      height: 15px;
      margin-right: 8px;
    }
  }
  .item {
    padding-left: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 20px;
    font-weight: 500;
    color: #111111;
    line-height: 32px;
    letter-spacing: 1px;
    a {
      color: #333333;
    }
  }
}

@media (max-width: 768px) {
  .container {
    .item-container {
      padding-bottom: 0.89rem;
      padding-top: 0.44rem;
      &:first-child::before {
        top: 0.09rem;
      }
      &::before {
        content: '';
        width: 0.18rem;
        height: 0.18rem;
        margin-top: 0.01rem;
        background-color: var(--main-color-1);
      }
      .item {
        padding-left: 0.32rem;
        padding-right: 0;
        font-size: 0.28rem;
        font-weight: 500;
        color: #111111;
        line-height: 0.44rem;
        a {
          letter-spacing: 0;
        }
      }
    }
    .list.has-date {
      .date {
        display: block;
        text-align: right;
        color: #d2c8c8;
        bottom: 0.18rem;
        img {
          width: .22rem;
          height: .22rem;
          margin-right: .12rem;
        }
      }
    }
  }
}
</style>