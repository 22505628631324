<template>
  <div class="container">
      <div class="img-box">
        <router-link to="/question/teacher">
        <img src="../../assets/images/participate/card-cjwt.png"/>
        </router-link>
        <router-link to="/guideline">
        <img src="../../assets/images/participate/card-hdzn.png"/>
        </router-link>
        <router-link to="/resource/yxal/js">
        <img src="../../assets/images/participate/card-hdzyb.png" />
        </router-link>
      </div>
      <div class="name"><img src="../../assets/images/participate/icon.png" />教师如何参与？</div>
      <div class="info">
        <p>
          教师通过活动网站注册，并根据学校或学生所填信息与学生小组账号进行关联，即可管理所辅导的学生小组，了解小组学生网上调查数据和报告的提交情况。
        </p>
      </div>

      <div class="name"><img src="../../assets/images/participate/icon.png" />教师如何指导学生开展活动？</div>
      <div class="info">
        <p>
          教师应尊重学生意愿，指导学生组成活动小组，并引导学生根据兴趣、能力、特长、活动需要，明确分工，培养学生的自主参与意识与合作沟通能力。在活动实施过程中，要处理好学生自主实践与教师有效指导的关系，成为学生活动的组织者、参与者和促进者。
        </p>
      </div>

      <div class="name"><img src="../../assets/images/participate/icon.png" />教师如何参与全国评选？</div>
      <div class="info">
        <p>
          在活动网站正式注册的学校在年度评选时具有推荐教师优秀科技实践活动报告参与全国评选的资格。提交科技实践活动报告参与评选的教师本年度须至少辅导一个学生小组参与活动。
        </p>
      </div>

      <div class="name">
        <img src="../../assets/images/participate/icon.png" />教师科技实践活动报告的评选标准是什么？
      </div>
      <div class="info">
        <p>教师提交的科技实践活动报告最好与活动主题相关，评选时将重点从教育性、科学性、可操作性等方面进行评价。</p>
      </div>
      <div class="text-center">
        <div class="step1"></div>
        <div class="focus-entrance">
          <div @click="jumpToTeacher">
            <img src="../../assets/images/participate/jsczzn.png" class="show-lg"/>
            <img src="../../assets/images/participate/jszn-m.png" class="show-mobile"/>
          </div>
        </div>
        <div class="step2"></div>
        <div class="guide-info">
          <div class="detail">
            <img class="book-img" @click="bookPdf" src="../../assets/images/participate/book.png" />
            <div class="book-info">
              <div class="intro">
                青少年科学调查体验活动，既可作为一项独立的科技活动内容，也可作为中小学综合实践活动课程或课外科技教育活动的内容，既可利用课堂时间，也可利用课后、周末、寒暑假等时间，组织学生以学校（班级或小组）、家庭为单位组织开展。
              </div>
              <!-- 一些示例文章 -->
              <div class="intro-articles">
                <div class="intro-article" @click="subpdf(12)">
                  <a href="#">青少年科学调查体验活动组织开展方式</a>
                </div>
                <div class="intro-article"  @click="subpdf(26)">
                  <a href="#">学校及教师在活动中的作用</a>
                </div>
                <div class="intro-article"  @click="subpdf(30)">
                  <a href="#">教师《活动指导教案》的撰写</a>
                </div>
                <div class="intro-article"  @click="subpdf(34)">
                  教师《活动指导教案》撰写案例剖析
                  <a href="#"  @click="subpdf(36)">活动案例一：科学探究类活动</a>
                  <a href="#"  @click="subpdf(48)">活动案例二：创意制作类活动</a>
                </div>
                <div class="intro-article"  @click="subpdf(66)">
                  <a href="#">《科技实践活动报告》的撰写及体例</a>
                </div>
              </div>

            </div>
          </div>
          <div class="experiences-container">
            <div class="title-experience">
              <router-link to="/participate/join/teacher/jyfx" class="link-more">查看更多&gt;</router-link>
              <div>经验分享</div>
            </div>
            <div class="experience-list clearfix">
              <div class="experience limit-line line1" v-for="item in experienceList" :key="item.id" @click="experienceClick(item.id)">
                <a href="#">{{ item.title }}</a>
              </div>
             
            </div>
          
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import {getDynamicList} from '../../api/api'

export default {
  data () {
    return {
      experienceList:[]
    }
  },
  methods: {
    jumpToTeacher(){
      this.$router.push('/article/1092')
    },
    bookPdf(){
      window.open(window.location.pathname+'pdf/web/viewer.html?pdf='+'https://cysccresource.cyscc.org/2023/0222/book.pdf')
    },
    subpdf(page){
      window.open(window.location.pathname+'pdf/web/viewer.html?pdf='+'https://cysccresource.cyscc.org/2023/0222/book.pdf#page='+page)

    },
    getData(){
      let params={
        type:3,
      }
      getDynamicList(params).then(res=>{
        this.experienceList=res.data.list
      })
    },
    experienceClick(id){
      this.$router.push('/article/'+id)
    }
   
  },
  created(){
    this.getData()
  }
}
</script>

<style scoped lang="less">
.container {
    .text-center {
      .step1 {
        width: 100%;
        height: 128px;
        background: url('../../assets/images/participate/step-teacher.png') no-repeat center;
        background-size: 100% 100%;
      }
      .focus-entrance {
        width: 100%;
        margin: 55px auto;
        cursor: pointer;
      }
      .step2 {
        width: 100%;
        height: 802px;
        background: url('../../assets/images/participate/step-2-t.png') no-repeat center;
        background-size: 100% 100%;
      }

      .guide-info {
        width: 100%;
        background: url('../../assets/images/participate/bg-text.jpg') no-repeat center;
        background-size: 100% 100%;
        padding-top: 46px;
        padding-left: 45px;
        padding-right: 43px;
        margin-top: 80px;
        .detail {
          display: flex;
          justify-content: space-between;
          gap:30px;
          text-align:left;
          margin-bottom: 40px;
          .book-img {
            width: 314px;
            height: 429px;
            cursor: pointer;
          }
          .book-info {
            .intro {
              color: #333333;
              line-height: 28px;
              text-align: justify;
              text-indent: 2em;
              margin-bottom: 50px;
            }
            .intro-articles{
              .intro-article{
                position:relative;
                line-height:2.5;
                padding-left:40px;  
                &::after{
                  content: '';
                  position:absolute;
                  left:0;
                  top:5px;
                  width: 24px;
                  height: 28px;
                  background: url("../../assets/images/participate/icon1.jpg") no-repeat center;
                  background-size: 100% 100%;
                }
                &::before{
                  content: '';
                  position:absolute;
                  left:11px;
                  top:2px;
                  bottom:0;
                  border-left:2px dotted #5293D2;
                }
                &:first-of-type{
                  &::before{
                    top:10px;
                  }
                }
                &:last-of-type{
                  &::before{
                    bottom:inherit;
                    height:10px;
                  }
                }
                a{
                  display: block;
                }
              }
            } 
          }
        }
        // 下方经验分享部分
        .experiences-container{
          padding-bottom:50px;
          text-align:left;
          .title-experience{
            border-top:2px solid #eee;
            padding-top:50px;
            font-size: 22px;
            font-weight: 600;
            color: #5293D2;
            line-height: 32px;
            margin-bottom:30px;
            .link-more{
              float:right;
              font-size: 16px;
              color: #999;
            }
          }
          .experience-list{
            .experience{
              width:50%;
              float:left;
              line-height:3em;

            }
          }
        }
      }
    }
  
}

@media (max-width: 768px) {
  .container {
    .text-center {
      .step1 {
        width: 100%;
        height: 3.62rem;
        background: url('../../assets/images/participate/step-teacher-m.png') no-repeat center;
        background-size: 100% 100%;
      }
      .focus-entrance {
          width: 100%;
          margin: .8rem auto;
          img {
          max-width: 100%;
          margin: 0 auto;
        }
        }
      .step2 {
        width: 100%;
        height: 10.41rem;
        background: url('../../assets/images/participate/step-teacger-3-m.png') no-repeat center;
        background-size: 100% 100%;
      }

      .guide-info {
        padding-top: .44rem;
        padding-left: .26rem;
        padding-right: .26rem;
        margin-top: .5rem;
        .detail {
          gap:.3rem;
          text-align:left;
          margin-bottom: .6rem;
          .book-img {
            width: 2.5rem;
            height: 3.41rem;
          }
          .book-info {
            .intro {
              font-size:.24rem;
              line-height: 1.8;
              margin-bottom: .5rem;
            }
            .intro-articles{
              margin-left:-2.7rem;

            } 
          }
        }
        // 下方经验分享部分
        .experiences-container{
          padding-bottom:.5rem;
          .title-experience{
            padding-top:.5rem;
            font-size: .38rem;
            line-height: 1.8;
            margin-bottom:.3rem;
            .link-more{
              float:right;
              font-size: .28rem;
              line-height:.7rem;
              color: #999;
            }
          }
          .experience-list{
            .experience{
              width:100%;
              float:none;
              line-height:2.2;
              position: relative;
              padding-left:.4rem;
              &::before{
                content: ' ';
                position:absolute;
                left:0;
                top:.2rem;
                width: .16rem;
                height: .16rem;
                background:#ccc;
                border-radius:50%;
              }

            }
          }
        }
      }
    }
  
}
}
</style>