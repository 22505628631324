<template>
  <div class="footer">
    <div class="main-width-container footer-block">
      <div class="footer-info">
        <div class="footer-logo">
          <a href="https://www.scienceday.org.cn/" target="_blank"><img src="../assets/images/footer/logo-2.png" alt="" /></a>
          <div class="footer-dw">
            <p>主办：中国科协 教育部 生态环境部 中央精神文明建设办公室 共青团中央</p>
            <p>承办：中国科协青少年科技中心（中国科协科普活动中心）</p>
          </div>
        </div>
        <div class="text">
          <p>
              <img src="../assets/images/footer/icon-mail@2x.png" alt="" />邮箱：scienceactivity@cast.org.cn
            </p>
         <p>
          <img src="../assets/images/footer/icon-phone.jpg" alt="" />电话：010-84897800
         </p>
         
          
            <span>京ICP备12019928号-2 </span>
            <img class="zs" src="@/assets/images/footer/zs.png" alt="" />
       
        </div>
        <div class="ewm">
          <div class="sph">
            <img src="../assets/images/footer/sph.png" alt="" />
             <div class="text-sph">视频号</div>
          </div>
          <div class="gzh">
            <img src="../assets/images/footer/gzh.png" alt="" />
             <div class="text-gzh">公众号</div>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.footer {
  background: url('@/assets/images/footer/bottom.png' ) top center/cover no-repeat;
  height: 270px;
  min-width: 1200px;
  font-size: var(--font-size-s);
  .footer-block {
    // padding-top: 67px;
  }
  .footer-info {
    display: flex;
    justify-content: center;
    align-items: center;
    .footer-logo {
      margin-right: 200px;
      margin-top: 30px;
      img {
        width: 468px;
        height: 105px;
        // width: 100%;
      }
      .footer-dw{
        margin-left: 20px;
        p{
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          margin: 10px;
          white-space: nowrap;
        }
      }
    }
    .text {
      color: #fff;
      line-height: 42px;
      text-align: left;
      padding-left: 100px;
      border-left: 1px solid #eee;
      margin-right: 50px;
      margin-top: 67px;
      p{
        white-space: nowrap;
   
        img {
          vertical-align: middle;
          margin-right: 10px;
          width: 20px;
          height: 14px;
        }
      }
      p:nth-child(2) {
        img {
          width: 19px;
          height: 19px;
        }
      }
      .zs{
        width:98px;
        display: block;
      }
    }
    .ewm {
      margin-top: 67px;
      display: flex;
      justify-content: space-between;
      img {
        width: 120px;
        height: 120px;
      }
      .sph{
        margin-right: 20px;

      }
      .text-sph {
        text-align: center;
        color: #fff;
      }
      .text-gzh {
        text-align: center;
        color: #fff;
      }
    }
  }
}
@media (max-width: 768px) {
  .footer {
    background: url('../assets/images/footer/bottom-m.png') center center/cover no-repeat;
    // height: 9.8rem;
    height: auto;
    min-width: auto;
    padding: 0 0 0.35rem;
    .footer-block {
      padding-top: 0.36rem;
      margin: 0;
    }
    .footer-dw{
      margin-left: 0.4rem !important;
    }
    .footer-info {
      display: block;
      margin-top: 0rem;
      font-size: var(--font-size-m-m);
      .footer-logo {
        width: 6.8rem;
        height: auto;
        margin-right: 0;
        margin-left: 0.32rem;
        margin-bottom: 0.4rem;
        padding-bottom: 0.34rem;
        border-bottom: 1px solid #606b74;
        margin-top: 0;
        img {
          width: 4.31rem;
          height: 0.88rem;
        }
        .footer-dw{
          p{
            margin-left:0rem;
            font-size: 0.25rem;
            white-space:normal;
          }
        }
      }
      .text {
        display: inline-block;
        width: 4.46rem;
        font-size: 0.25rem;
        margin-right: 0;
        padding-left: 0.72rem;
        line-height: 0.58rem;
        text-align: left;
        border-left:0;
        margin-top: 0;
        p{
          img {
            width: 0.33rem;
            height: 0.26rem;
            margin-right: 0.21rem;
          }
        }
        p:nth-child(2) {
        img {
          width: 0.38rem;
            height: 0.38rem;
            margin-right: 0.18rem;
        }
      }
        .zs{
          width:2rem;
        }
      }
      
      .ewm {
        margin: 0 0.72rem;
        margin-top: 0.4rem;
        img {
          width: 2.7rem;
          height: 2.7rem;
          margin-bottom: 0.1rem;
        }
      }
    }
  }
}
</style>
