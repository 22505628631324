<template>
  <div class="header">
    <div class="top">
      <div class="logo">
        <a :href="pathName"><img src="../../assets/images/header/logo-1.png" alt="" class="logo-cxds" /></a>
        <a class="show-lg" href="https://www.cyscc.org/" target="_blank"
          ><img src="../../assets/images/header/logo-kczm.png" alt="" class="logo-kczm"
        /></a>
        <div
          :class="'mobile-menu ' + (mobileMenuActive ? 'active' : '')"
          @click="mobileMenuActive = !mobileMenuActive"
        ></div>
      </div>
    </div>
    <div class="nav-bar">
      <HeaderNav :isOpen="mobileMenuActive" @isMenuClose="isMenuClose"/>
      <div class="btn-group">
        <a class="user-btn login" target="_blank" href="https://newpassport.cyscc.org/login?back_url=https://sciencedayuser.cyscc.org">登录</a>
        <a class="user-btn reg" target="_blank" href="https://newpassport.cyscc.org/signup?back_url=https://sciencedayuser.cyscc.org">注册</a>
        
      </div>
    </div>
  </div>
</template>

<script>
import HeaderNav from './HeaderNav.vue'
export default {
  data () {
    return {
      mobileMenuActive: false,
      pathName:window.location.pathname
    }
  },
  components: {
    HeaderNav,
  },
  methods : {
    isMenuClose(val) {
      this.mobileMenuActive = val
    }
  }
}
</script>

<style scoped lang="less">
.header {
  width: 100%;
  min-width: var(--main-width);
  background-color: #fff;
  z-index:2;
  position: relative;
  .top {
    width: 100%;
    border-bottom: 2px solid rgba(138, 157, 170, 0.1);
    margin-bottom:-3px;
    .logo {
      width: var(--main-width);
      height: 88px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      .logo-cxds {
        width: 300px;
      }
      .logo-kczm {
        width: 174px;
      }
    }
  }
}
.nav-bar {
  display: flex;
  justify-content: space-between;
  width: var(--main-width);
  margin: 0 auto;

  .user-btn{
    display:inline-block;
    margin: 13px 0;
    border-radius: 17px;
    height: 34px;
    line-height: 34px;
    width: 67px;
    text-align: center;
    background: #fff;
    font-size: 16px;
    color: #3580ff;
    &.reg {
      color: #497ef6;
      background-color: #d8e6ff;
      padding-left: 20px;
      width:85px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &.login {
      position:relative;
      color: #fff;
      background-color: #497ef6;
      margin-right: -30px;
      padding: 0 4px;
      z-index:1;
    }
  }
}
.mobile-menu {
  display: none;
}

@media (max-width: 768px) {
  .header {
    width: 100%;
    min-width: inherit;
    padding: 0.2rem 0.35rem;
    .show-lg {
      display: none;
    }
    .top {
      min-width: auto;
      border-bottom: none;

      .logo {
        width: 100%;
        height: 1rem;

        .logo-cxds {
          width: 3.55rem;
          height: .81rem;
          vertical-align: middle;
        }

        .mobile-menu {
          display: block;
          background: url('../../assets/images/header/menu.png') no-repeat center;
          background-size: 0.4rem 0.31rem;
          width: 0.4rem;
          height: 0.4rem;
        }
        .mobile-menu.active {
          background-image: url('../../assets/images/header/close.png');
          background-size: 0.32rem 0.32rem;
        }
      }
    }
    .nav-bar {
      width: inherit;
      display: block;
      .user-btn{
        display:none;
      }
    }
  }
}
</style>
