<template>
  <div class="page">
    <div class="video-container main-width-container">
      <div class="video-title">{{ videoData.title }}</div>
      <div class="video-info">
        <div class="create-time">{{ videoData.publishTime }}</div>
        <div class="author">{{ videoData.sourceName }}</div>
      </div>
      <div class="desc">{{ videoData.content }}</div>
      <video class="video-body" :src="videoData.videoUrl" autoplay controls></video>
    </div>
  </div>
</template>

<script>
// import { videoData } from '../../public/testData/testDataDetail'
export default {
  data () {
    return {
      videoData: videoData.data,
      id : this.$route.params.id
    }
  },
  components: {

  },
  created () {
  },
  methods: {
  
  }
}
</script>


<style scoped lang="less">
.page {
  background-position: center top;
  background-size: cover;
  margin-bottom: -160px;
  padding-bottom: 300px;
  .desc {
    margin-bottom: 20px;
    line-height: 1.8;
    color: #333;
    letter-spacing: 1px;
    font-size: var(--font-size-l);
  }

  .video-container {
    margin-top: 80px;
    background: #fff;
    border-radius: 20px;
    padding: 50px 50px 50px;

    .video-title {
      text-align: center;
      font-size: var(--font-size-xl);
      margin: 0 0 20px;
    }
    .video-info {
      text-align: center;
      color: #999;
      margin-bottom: 50px;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;

      div {
        display: inline-block;
        margin: 0 10px;
        letter-spacing: 1px;
      }
    }

    .video-body {
      width: 100%;
      height: 100%;
      /deep/img {
        display: block;
        max-width: 100%;
        margin: 20px auto 10px;
      }
    }
  }
}

@media (max-width: 768px) {
  .page {
    margin-bottom: -3rem;
    padding-bottom: 4.2rem;
    .desc {
      margin-bottom: 0.3rem;

      letter-spacing: 1px;
      font-size: var(--font-size-l-m);
    }

    .video-container {
      margin-top: 0;
      background: transparent;
      border-radius: 0;
      padding: 0.5rem 0;
      box-shadow: none;

      .video-title {
        text-align: center;
        font-size: var(--font-size-xl-m);
        margin: 0 0 0.2rem;
      }
      .video-info {
        text-align: center;
        color: #999;
        margin-bottom: 0.5rem;
        padding-bottom: 0.3rem;

        div {
          margin: 0.1rem 0.1rem;
          letter-spacing: 0.02rem;
        }
      }

      .video-body {
        width: 100%;
        height: 100%;
        /deep/img {
          display: block;
          max-width: 100%;
          margin: 0.1rem auto;
        }
      }
    }
  }
}
</style>