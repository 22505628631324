<template>
      <div class="bread-crumb">
        <a v-for="item in crumbDataUrl" :href="item.url">{{item.name}}</a>
       
      </div>
</template>
<script>
export default {
  props: {
    crumbData: {
      type: Array,
      require: true,
    }
  },
  computed: {
    // 处理crumbData的url
    crumbDataUrl(){
      let crumbDataUrl = this.crumbData;
      crumbDataUrl.forEach((item,index) => {
        if(item.url&&window.location.pathname != '/'){
          item.url = window.location.pathname.replace(/\/$/,'') + item.url;
        }else{
          item.url = item.url;
        }

      })
      return crumbDataUrl;
    }
  },
  data(){
    return {
    }
  },
  mounted(){
   
  },
}
</script>
<style lang="less" scoped>
 .bread-crumb {
    padding: 0;
    margin-bottom: 20px;
    &>* {
      display: inline-block;
      font-size: 14px;
      color: #333;
      margin-right: 0px;
      &:after {
        content: '/';
        margin: 0 10px;
      }
    }
    &>*:last-child{
      color:#5293D2;
      &:last-child:after {
      content: '';
      }
    }

  }
@media (max-width: 798px) {
  .bread-crumb {
    margin-top:.2rem;
    margin-bottom:.2rem;
  }
}
</style>