<template>
  <div class="card-simple">
    <div class="type-container" >
          <img :src="cardData.image" alt="">
          <div class="card-title">{{ cardData.title }}</div>
        </div>
        <div class="btn-group">
            <a href="###" @click="hdznClick">活动指南</a>
            <router-link :to="'/guideline/zyb/list/'+cardData.id">相关资源</router-link>
            <a :href="cardData.courseUrl" :class="cardData.courseUrl?'':'no-data'" target="_blank">活动课程</a>
          </div>
  </div>
</template>

<script>

export default {
  name: 'CardItemZyb',
  props: {
    cardData: {
      type: Object,
      require: true
    },
  },
  mounted () {
   
  },
  data () {
    return {
      type: 'type-'+this.cardData.type
    }
  },
  methods: {
    hdznClick(){
      window.open(window.location.pathname+'pdf/web/viewer.html?id='+this.cardData.id)
    }
  },
  computed:{
   
  }
}
</script>

<style scoped lang="less">
.card-simple {
  position: relative;
  // width: 431px;
  // height: 146px;
  width: 277px;
  height: 200px;
  background: #fff;
  overflow: hidden;
  border-radius: 0;
  box-shadow: 0px 7px 24px 0px rgba(136, 136, 136, 0.17);
  display: inline-block;
  // padding:8px 16px 0;
  margin-right: 30px;
  margin-bottom: 25px;
  // border-bottom: 3px solid #5293D2;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .content{
    display: flex;
  }
  .type-container{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 277px;
    height: 157px;
    position: relative;
   img{
    width: 277px;
    height: 157px;
    display: block;
   }
  }

  .card-title {
    width: 100%;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    font-size: var(--font-size-m);
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .4);
  }
  .btn-group{
    display:flex;
    height:44px;
    background: rgba(82, 147, 210, .1);

    a{
      display:block;
      // width:33.5%;
      padding: 0 16px;
      text-align:center;
      font-size:15px;
      line-height:40px;
      color:#5293d2;
      
      // background:#EDF4FC;
      position:relative;
      &:hover{
        color:#116cc7;
      }
      &::after{
        content:' ';
        position:absolute;
        display:block;
        width:1px;
        height:13px;
        background:#ccc;
        position:absolute;
        right:0;
        top:50%;
        margin-top:-6.5px;
      }
      &:last-child{
        padding-right: 0;
        &::after{
          display:none;
        }
      }
      &.no-data{
        color:#ccc;
        cursor:default;
      }
    }
  }
}

@media (max-width: 768px) {
  .card-simple {
  width: 100%;
  height: 3.94rem;
  display: block;
  // padding:.15rem .25rem;
  margin-right: 0;
  margin-bottom: .3rem;
 
  .type-container{
    width: 100%;
    height: 157px;
    height: 3.14rem;
    img{
      width: 100%;
      height: 3.14rem;
      display: block;
      
   }
  }

  .card-title {
    padding-left: .35rem;
    margin-top: .2rem;
    height:0.8rem;
    font-size: var(--font-size-l-m);
    line-height: 0.8rem;
  }
  .btn-group{ 
    height:.8rem;
    a{
      
      font-size:.28rem;
      line-height:.8rem;
      padding: 0 0.62rem;
      &::after{
 

        width:1px;
        height:.24rem;
        background:#ccc;
        position:absolute;
        right:0;
        top:50%;
        margin-top:-.12rem;
      }
    }
  }
}
}
</style>