<template>
  <div id="app">
    <HeaderComponent></HeaderComponent>
    <keep-alive include="dynamic,news"><router-view :key="$route.fullPath"></router-view></keep-alive>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
export default {
  name: 'App',
  data () {
    return {
      //
    }
  },

  components: {
    HeaderComponent,
    FooterComponent,

  }
}
</script>

