<template>
  <div class="card-simple">
    <router-link :to="cardData.link">
      <div class="img-container">
        <img :src="cardData.image" alt="" />
        <div v-if="showView" class="view-count">
          {{ cardData.readCount }}
          <div class="watch"></div>
        </div>
        <div :class="'content-type '+contentType" v-if="contentType"></div>
      </div>
      <div class="card-title">{{ cardData.title }}</div>
      <div v-show="showDate" class="create-time">{{ cardData.publishTime.slice(0,10) }}</div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'CardItem',
  props: {
    cardData: {
      type: Object,
      require: true
    },
    showDate: {
      type: Boolean,
      default: true
    },
    showView:{
      type:Boolean,
      default:true
    },
    // 卡片对应的类型。如下：article,photo,video
    contentType:{
      type:String,
      default:''
    }

  },
  data () {
    return {
      
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.card-simple {
  position: relative;
  width: 277px;
  height: 270px;
  background: #fff;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 7px 24px 0px rgba(136, 136, 136, 0.17);
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 25px;
  &:nth-child(4n) {
    margin-right: 0;
  }
  .img-container {
    width: 277px;
    height: 157px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .view-count {
      position: absolute;
      width: 71px;
      height: 20px;
      padding-left: 25px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 10px 0px 0px 10px;
      right: 0;
      bottom: 10px;
      color: #fff;
      font-size: var(--font-size-s);
      .watch {
        position: absolute;
        width: 13px;
        height: 9px;
        // background: url('../../assets/images/eye.png') center no-repeat;
        background-size: 100% 100%;
        top: 5px;
        left: 8px;
      }
    }

    .content-type{
      width: 25px;
      height: 20px;
      position: absolute;
      bottom: 10px;
      left:5px;
      background-repeat: no-repeat;
      background-size:100% 100%;
      // &.video{
      //   background-image:url("../../assets/images/card-icon-video.png");
      // }
      // &.photo{
      //   background-image:url("../../assets/images/card-icon-pic.png");
      // }
    }
  }
  .card-title {
    padding-left: 15px;
    padding-right: 26px;
    margin-top: 20px;
    font-size: var(--font-size-m);
    color: #111;
    line-height: 26px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .create-time {
    position: absolute;
    font-size: var(--font-size-s);
    color: #999;
    left: 15px;
    bottom: 12px;
  }
}

@media (max-width: 768px) {
  .card-simple {
    width: 3.29rem;
    height: 3.3rem;
    margin: 0.12rem 0rem;
    margin-right: .282rem;
    border-radius:.12rem;
    &:nth-child(2n) {
      margin-right: 0;
    }
    .img-container {
      width: 3.29rem;
      height: 1.85rem;
      img {
        width: 100%;
        height: 100%;
        margin: 0rem 0rem 0.1rem 0rem;
      }

       .view-count {
     
      width: 1.2rem;
      height: .38rem;
      line-height:.38rem;
      padding-left: .38rem;
      border-radius: .19rem 0px 0px .19rem;
      bottom: .1rem;
  
      font-size: var(--font-size-s-m);
      .watch {
        position: absolute;
        width: .22rem;
        height: .15rem;
        background: url('../../assets/images/eye-m.png') center no-repeat;
        background-size:100% 100%;
        top: .12rem;
        left: .12rem;
      }
    }
    .content-type{
      width: .35rem;
      height: .28rem;
      position: absolute;
      bottom: .16rem;
      left:.1rem;


    }


    }
    .card-title {
      margin: 0.15rem 0.21rem 0.19rem 0.12rem;
      padding: 0;
      line-height: 0.4rem;
      font-size: var(--font-size-l-m);
      color: #111;
    }
    .create-time {
      font-size: var(--font-size-s);
      left: 0.13rem;
      bottom: 0.07rem;
    }

    
 

  }
}
</style>