import { render, staticRenderFns } from "./Zyb.vue?vue&type=template&id=47b8c310&scoped=true"
import script from "./Zyb.vue?vue&type=script&lang=js"
export * from "./Zyb.vue?vue&type=script&lang=js"
import style0 from "./Zyb.vue?vue&type=style&index=0&id=47b8c310&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47b8c310",
  null
  
)

export default component.exports