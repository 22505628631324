import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/base.css'
import './styles/reset.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
//按需引入echarts 
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/map'
// import 'echarts/lib/component/tooltip'
// import 'echarts/lib/component/title'
import 'echarts/lib/component/visualMap'

import china from 'echarts/map/json/china.json'
import { Pagination,Tabs, TabPane, Select, Option,Input,Button } from 'element-ui';
Vue.use(Pagination)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Select)
Vue.use(Option)
Vue.use(Input)
Vue.use(Button)
Vue.prototype.$echarts = echarts
echarts.registerMap('china', china)
Vue.config.productionTip = false

// 跳转页面时返回顶部
router.afterEach((to, from) => {
  window.scrollTo(0, 0)
})


Vue.use(VueAwesomeSwiper)
Vue.prototype.$VUE_APP_PUBLIC = process.env.VUE_APP_PUBLIC;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
