<template>
  <div class="page">
    <div class="banner" :style="{backgroundImage:'url('+bannerImg+')'}">
   
    </div>
    <Menu :menuData="menuData"></Menu>
   
  </div>
</template>

<script>
import Menu from '../../components/Menu.vue'
export default {
  components: {
    Menu
  },
  data () {
    return {
      menuData: [
        { link: '/resource/jskc', name: '教师课程'},
        { link: '/resource/yxal', name: '活动案例'},
        // { link: '/resource/zyb', name: '资源包'},
        {link:'/resource/yxal/xs',name:'学生调查报告',parent:'活动案例'},
        {link:'/resource/yxal/js',name:'教师实践报告',parent:'活动案例'},
      ],
    }
  },
  computed: {
    bannerImg () {
      const isMobile = window.innerWidth>798?'':'-m';
      let bannerName = '';
      switch (this.$route.path) {
        case '/resource/jskc':
          bannerName = 'banner-jskc';
          break;
        case '/resource/zyb':
          bannerName = 'banner-zyb';
          break;
        case '/resource/yxal':
        case '/resource/yxal/js':
        case '/resource/yxal/xs':
          bannerName = 'banner-yxal';
          break;
      }
      if(this.$route.path.indexOf('/resource/zyb')>-1){
        bannerName = 'banner-zyb';
      }
      let fileName = bannerName + isMobile + '.jpg';
      return require('../../assets/images/resource/' + fileName);
    
    },

  },
}
</script>

<style scoped lang="less">
.banner{
  width: 100%;
  height: 400px;
  background-position:center;
  background-size: 1920px 100%;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .banner {
    width: 7.5rem;
    height: 2.68rem;
    background-size:100% 100%;
  }
}
</style>