<template>
    <div class="page">
      <!-- <div class="banner"></div>  -->
      <div class="main-width-container">
        <div class="text-center">
          <!-- <div class="article-name">2022青少年科学调查体验活动线上参与指南</div>
          <div class="article-time">本文发布于：2022-06-13</div> -->
          <div class="article-title">活动咨询</div>
          <!-- <div class="guideline-list">
            <div class="item" @click="jumpToStudent">学生线上参与指南</div>
            <div class="item" @click="jumpToTeacher">教师线上参与指南</div>
            <div class="item" @click="jumpToSchool">学校线上参与指南</div>
            <div class="item" @click="jumpToQuestion">注册申报常见问题</div>
          </div> -->
          <div class="email">邮箱：scienceactivity@cast.org.cn</div>
          <div class="phone">电话：010-84897800（工作日 09:00-18:00）</div>
          <div class="article-title">各省联系方式</div>
          <table>
            <tr>
              <th>序号</th>
              <th>省份</th>
              <th>联系人</th>
              <th>联系方式</th>
            </tr>
            <tr>
              <td>1</td>
              <td>北京</td>
              <td>
               董老师
              </td>
              <td>
                010-83059937
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>天津</td>
              <td>杨老师</td>
              <td>022-23005035<br/>022-87898875</td>
            </tr>
            <tr>
              <td>3</td>
              <td>河北</td>
              <td>杨老师</td>
              <td>0311-86052468</td>
            </tr>
            <tr>
              <td>4</td>
              <td>山西</td>
              <td>崔老师</td>
              <td>0351-6042232</td>
            </tr>
            <tr>
              <td>5</td>
              <td>内蒙古</td>
              <td>赵老师</td>
              <td>0471-6929926</td>
            </tr>
            <tr>
              <td>6</td>
              <td>辽宁</td>
              <td>张老师</td>
              <td>024-23785515</td>
            </tr>
            <tr>
              <td>7</td>
              <td>吉林</td>
              <td>付老师</td>
              <td>0431-85261351</td>
            </tr>
            <tr>
              <td>8</td>
              <td>黑龙江</td>
              <td>李老师</td>
              <td>0451-82650260&nbsp;</td>
            </tr>
            <tr>
              <td>9</td>
              <td>上海</td>
              <td>蒋老师</td>
              <td>021-33311679</td>
            </tr>
            <tr>
              <td>10</td>
              <td>江苏</td>
              <td>汤老师</td>
              <td>025-86670718</td>
            </tr>
            <tr>
              <td>11</td>
              <td>浙江</td>
              <td>郭老师</td>
              <td>0571-85090520</td>
            </tr>
            <tr>
              <td>12</td>
              <td>安徽</td>
              <td>徐老师</td>
              <td>0551-62845749</td>
            </tr>
            <tr>
              <td>13</td>
              <td>福建</td>
              <td>林老师</td>
              <td>0591-83341749</td>
            </tr>
            <tr>
              <td>14</td>
              <td>江西</td>
              <td>简老师</td>
              <td>0791-86656372</td>
            </tr>
            <tr>
              <td>15</td>
              <td>山东</td>
              <td>胡老师</td>
              <td>0531-86064892</td>
            </tr>
            <tr>
              <td>16</td>
              <td>河南</td>
              <td>郝老师</td>
              <td>0371-65700207</td>
            </tr>
            <tr>
              <td>17</td>
              <td>湖北</td>
              <td>涂老师</td>
              <td>027-87828781</td>
            </tr>
            <tr>
              <td>18</td>
              <td>湖南</td>
              <td>彭老师</td>
              <td>0731-84910575</td>
            </tr>
            <tr>
              <td>19</td>
              <td>广东</td>
              <td>彭老师</td>
              <td>020-28328352</td>
            </tr>
            <tr>
              <td>20</td>
              <td>广西</td>
              <td>董老师</td>
              <td>0771-2809218</td>
            </tr>
            <tr>
              <td>21</td>
              <td>海南</td>
              <td>梁老师</td>
              <td>0898-65340396</td>
            </tr>
            <tr>
              <td>22</td>
              <td>重庆</td>
              <td>盛老师</td>
              <td>023-63659911</td>
            </tr>
            <tr>
              <td>23</td>
              <td>四川</td>
              <td>常老师</td>
              <td>028-85212399</td>
            </tr>
            <tr>
              <td>24</td>
              <td>贵州</td>
              <td>颜老师</td>
              <td>0851-85832855</td>
            </tr>
            <tr>
              <td>25</td>
              <td>云南</td>
              <td>王老师</td>
              <td>0871-65192792</td>
            </tr>
            <tr>
              <td>26</td>
              <td>西藏</td>
              <td>王老师</td>
              <td>0891-6835245</td>
            </tr>
            <tr>
              <td>27</td>
              <td>陕西</td>
              <td>王老师</td>
              <td>029-87259438</td>
            </tr>
            <tr>
              <td>28</td>
              <td>甘肃</td>
              <td>陈老师</td>
              <td>0931-6184272</td>
            </tr>
            <tr>
              <td>29</td>
              <td>青海</td>
              <td>王老师</td>
              <td>0971-6273964</td>
            </tr>
            <tr>
              <td>30</td>
              <td>宁夏</td>
              <td>高老师</td>
              <td>0951-5085155&nbsp;</td>
            </tr>
            <tr>
              <td>31</td>
              <td>新疆</td>
              <td>沙老师</td>
              <td>0991-6386515</td>
            </tr>
            <tr>
              <td>32</td>
              <td>兵团</td>
              <td>罗老师</td>
              <td>0991-2896182</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    data () {
      return {
      }
    },
    methods: {
      // jumpToStudent () {
      //   this.$router.push('/article/1094')
      // },
      // jumpToTeacher () {
      //   this.$router.push('/article/1092')
      // },
      // jumpToSchool () {
      //   this.$router.push('/article/1093')
      // },
      // jumpToQuestion () {
      //   this.$router.push('/article/1090')
      // },
    }
  }
  </script>
  
  <style scoped lang="less">
  .page {
    // background: url('../../assets/images/guideline/bg.png') no-repeat center;
    // background-size: 100% 100%;
    // min-height:1000px;
    width: 890px;
    margin-left: 31px;
    padding-top: 55px;
    padding-bottom: 55px;
    min-width: 890px;
    .main-width-container{
      width: 890px;
      margin: 0 auto;
    }
  }
  // .banner {
  //   height: 400px;
  //   width: 100%;
  //   background: url('../../assets/images/guideline/banner.jpg') no-repeat center;
  //   background-size: 1920px 400px;
  // }
  .article-name {
    font-size: 24px;
    color: #111111;
    line-height:1.8;
    margin-top: 45px;
    margin-bottom: 14px;
  }
  .article-time {
    font-size: 14px;
    color: #666666;
    line-height: 32px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    margin-bottom: 40px;
  }
  .article-title {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    line-height: 36px;
  }
  // .guideline-list {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   .item {
  //     width: 301px;
  //     height: 62px;
  //     border: 1px solid #d3e1ea;
  //     font-size: 18px;
  //     color: var(--main-color-1);
  //     line-height: 62px;
  //     margin: 33px auto 46px;
  //     cursor: pointer;
  //   }
  // }
  .phone {
    margin-bottom: 48px;
    line-height: 32px;
  }
  .email {
    margin-top: 48px;
    line-height: 32px;
    // margin-bottom: 25px;
  }
  table {
    width: 100%;
    margin-top: 30px;
    text-align: center;
    border: 1px solid #d5e1eb;
    th {
      background: #e6f1fe;
      font-size: 16px;
      color: #333;
      line-height: 2;
      text-align: center;
      border: 1px solid #d5e1eb;
    }
    tr {
      width: 100%;
      border-bottom: 1px dotted #e8e8e8;
      height: 40px;
    }
    td {
      border: 1px solid #d5e1eb;
      background: #fff;
    }
  }
  
  @media (max-width: 768px) {
    .page {
      background: none;
      min-height: 100%;
      width: 100%;
      min-width: 100%;
      padding-top: 1.5rem;
      padding-bottom: 0;
      margin-left: 0;

      .main-width-container{
        width: 100%;
        margin: 0 auto;
      }
    }
    // .banner {
    //   height: 2.68rem;
    //   background: url('../../assets/images/dynamic/banner-hd-m.jpg') no-repeat center;
    //   background-size: 100% 100%;
    // }
    .article-name {
      font-size: 0.34rem;
      line-height: 0.48rem;
      margin-bottom: 0.31rem;
    }
    .article-time {
      font-size: 0.24rem;
      line-height: 0.32rem;
      padding-bottom: 0.4rem;
      margin-bottom: 0.32rem;
    }
    .article-title {
      font-size: 0.28rem;
      font-weight: 600;
      color: #111111;
      line-height: 0.32rem;
    }
    .guideline-list {
      display: block;
      margin: 0.39rem auto 0.5rem;
      .item {
        display: inline-block;
        width: 3.43rem;
        height: 0.91rem;
        border: 1px solid #d3e1ea;
        font-size: 0.28rem;
        color: #3b93d7;
        line-height: 0.91rem;
        margin: 0;
        &:nth-child(3),
        &:nth-child(4) {
          margin-top: -1px;
        }
        &:nth-child(2),
        &:nth-child(4) {
          margin-left: -1px;
        }
      }
    }
    .phone,
    .email {
      // margin-bottom: 0.39rem;
      font-size: 0.28rem;
      font-weight: 600;
      color: #111111;
    }
    .email {
      margin-top: 0.48rem;
      // margin-bottom: 0.69rem;
    }
    .phone{
      margin-bottom: 0.48rem;
    }
    table {
      width: 100%;
      margin-top: 0.46rem;
      th {
        font-size: 0.22rem;
      }
      tr {
        width: 100%;
        height: .7rem;
      }
      td {
        font-size: 0.22rem;
      }
    }
  }
  </style>