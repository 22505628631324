import request from '@/utils/request'

// 常见问题列表
export const getProblemsList = (type) => {
  return request({
    method: 'GET',
    url: '/api/findProblemList?type=' + type
  })
}

// 活动动态列表
export const getDynamicList = (params) => {
  return request({
    method: 'GET',
    url: '/api/findNewsPageList',
    params,
  })
}

// 省份排名
export const getProvinceRank = () => {
  return request({
    method: 'GET',
    url: '/api/findProvinceListExt',
  })
}


// 市区排名
export const getCityRank = (params) => {
  return request({
    method: 'GET',
    url: '/api/findCityRankListExt',
    params,
  })
}


// 学校排名
export const getSchoolRank = (params) => {
  return request({
    method: 'GET',
    url: '/api/findSchoolRankListExt',
    params,
  })
}


// 学校top10排名
export const getSchoolTopRank = () => {
  return request({
    method: 'GET',
    url: '/api/findSchoolRankListTop10',
  })
}

//新学校top10排名
export const getSchoolTopRankNew = () => {
  return request({
    method: 'GET',
    url: '/api/findSchoolTop10Ext',
  })
}

// 活动动态详情
export const getDynamicDetail = (id) => {
  return request({
    method: 'GET',
    url: '/api/findNewsById?id=' + id,
  })
}


// 首页轮播
export const getSwiperList = () => {
  return request({
    method: 'GET',
    url: '/api/findBannerList',
  })
}

// 首页地图
export const getMapData = () => {
  return request({
    method: 'GET',
    url: '/api/findProvinceMapList',
  })
}
//首页地图改
export const getMapDataNew = () => {
  return request({
    method: 'GET',
    url: '/api/findHomeListExt',
  })
}

// 首页活动动态
export const getActivityData = () => {
  return request({
    method: 'GET',
    url: '/api/findNewAndNoticeList',
  })
}

// 获取案例年份
export const getYearData = () => {
  return request({
    method: 'GET',
    url: '/api/findYearList',
  })
}

// 获取案例省份
export const getProvinceData = () => {
  return request({
    method: 'GET',
    url: '/api/findProvinceList',
  })
}

// 获取案例主题
export const getThemeData = () => {
  return request({
    method: 'GET',
    url: '/api/findThemeListBak',
  })
}

// 查询优秀案例
export const getCaseData = (params) => {
  return request({
    method: 'GET',
    url: '/api/findCaseList',
    params
  })
}

//教师课程列表
export const getTeacherCourseData = (page,size) => {
  return request({
    method: 'GET',
    url: '/api/findTeacherCoursesList',
    params:{
      page,
      size
    }
  })
}

//查询资源包列表
export const getResourceData = (page=0,type,size=10) => {
  return request({
    method: 'GET',
    url: '/api/findResourceParentsList',
    params:{
      page,
      size,
      type
    }
  })
}

//根据资源包id查询相关资源列表
export const getResourceListById = (parentId) => {
  return request({
    method: 'GET',
    url: '/api/findResourceList',
    params:{
      parentId
    }
    
  })
}

//根据资源id查询相关资源详情
export const findResourceById = (id) => {
  return request({
    method: 'GET',
    url: '/api/findResourceById',
    params:{
      id
    }
    
  })
}

//参与活动查询
export const findActivityIntroduction = () => {
  return request({
    method: 'GET',
    url: '/api/findActivityIntroduction'
    
  })
}