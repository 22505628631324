<template>
  <div class="page">
    <div class="banner"></div>
      <Menu :menuData="menuData"></Menu>
  </div>
</template>

<script>
import Menu from '../../components/Menu.vue'
export default {
  data () {
    return {
      menuData: [
        { link: '/question/all', name: '全部', id: 1 },
        { link: '/question/student', name: '学生', id: 3 },
        { link: '/question/teacher', name: '教师', id: 2 },
        { link: '/question/admin', name: '学校管理员', id: 4 }
      ]
    }
  },
  components: {
    Menu
  }
}
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: 1920px 100%;
  background-repeat: no-repeat;
  background-image: url('../../assets/images/question/banner-cjwt.jpg');
}
.question-block {
  background-color: #fff;
}

@media (max-width: 768px) {
  .banner {
    height: 2.68rem;
    background: url('../../assets/images/question/banner-m.jpg') no-repeat center;
    background-size: 100% 100%;
  }
}
</style>  