<template>
  <div class="card-simple">
    <a :href="cardData.url" target="_blank">
      <div class="subject">
        {{ cardData.theme }}
      </div>
      <div class="card-title">{{ cardData.title }}</div>
      <div class="desc-info">
        <div class="author">作者：{{ cardData.author}}</div>
        <div class="teacher" v-if="cardData.guide">指导：{{cardData.guide}}</div>
        <div class="from">{{ cardData.province + (cardData.city?'-'+cardData.city:'')}}</div>
        <div class="school">{{cardData.school}}</div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'CardItem',
  props: {
    cardData: {
      type: Object,
      require: true
    },
    showDate: {
      type: Boolean,
      default: true
    },
    showView:{
      type:Boolean,
      default:true
    },
    // 卡片对应的类型。如下：article,photo,video
    contentType:{
      type:String,
      default:''
    }

  },
  data () {
    return {
      
    }
  },
  methods: {

  },
  computed:{

  }
}
</script>

<style scoped lang="less">
.card-simple {
  vertical-align: top;
  position: relative;
  width: 275px;
  height: 283px;
  background-image:url("../../assets/images/resource/card-bg-case.jpg");
  background-size:550px 283px;
  background-repeat: no-repeat;
  background-position:-275px 0 ;

  box-shadow: 0px 7px 24px 0px rgba(136, 136, 136, 0.17);
  display: inline-block;
  padding-left:30px;
  margin-right: 33px;
  margin-bottom: 25px;
  &:nth-child(3n) {
    margin-right: 0;
  }
  &:hover{
    background-position:0 0;
    &::after{
    content: ' ';
    
    width:100%;
  }
  }
  &::after{
    content: ' ';
    position: absolute;
    left:0;

    height: 4px;
    bottom:0;
    background: #5293D2;
    width:0%;
    transition:all .2s ease-in-out;
  }
  .subject{
    position: absolute;
    background:#f4f7ea;
    color:#96b12e;
    font-weight: bold;
    height:32px;
    line-height:32px;
    padding:0 10px;
    left:-10px;
    top:15px;
    &::before{
      content: ' ';
      position: absolute;
      width: 10px;
      height: 8px;
      bottom:-8px;
      border-bottom:8px solid transparent;
      border-right:8px solid #96b12e;
      left: 0;
     
    }
  }

  .card-title {
    color: #5293D2;
    padding-left: 0;
    padding-right: 30px;
    margin-top: 70px;
    height:57px;
    font-size: var(--font-size-m);
    font-weight: bold;
    line-height: 1.8;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom:25px;
  }
  .desc-info{
    color:#666;
    line-height:30px;
    height:30px;
    font-size:14px;
    .author{
      background-image:url("../../assets/images/resource/icon-author@2x.png");
      background-size:14px 17px;
      background-repeat: no-repeat;
      background-position:left center;
      padding-left:20px;
    }
    .teacher{
      background-image:url("../../assets/images/resource/icon-teacher@2x.png");
      background-size:16px 17px;
      background-repeat: no-repeat;
      background-position:left center;
      padding-left:20px;
    }
    .from{
      background-image:url("../../assets/images/resource/icon-province@2x.png");
      background-size:14px 18px;
      background-repeat: no-repeat;
      background-position:left center;
      padding-left:20px;
    }
    .school{
      background-image:url("../../assets/images/resource/icon-school@2x.png");
      background-size:16px 16px;
      background-repeat: no-repeat;
      background-position:left center;
      padding-left:20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
}

@media (max-width: 768px) {
  .card-simple {
    vertical-align: top;
 
  width: 3.31rem;
  height: 3.68rem;
  background-size:6.62rem 3.68rem;
  background-position:0 0 ;
  padding-left:.35rem;
  margin-right: .3rem;
  margin-bottom: .275rem;
  &:nth-child(2n) {
    margin-right: 0;
  }
  &:nth-child(2n+1) {
    margin-right: .275rem;
  }

  .subject{
    position: absolute;
    background:#f4f7ea;
    color:#96b12e;
    font-weight: bold;
    height:32px;
    line-height:32px;
    padding:0 10px;
    left:-10px;
    top:15px;
    &::before{
      content: ' ';
      position: absolute;
      width: 10px;
      height: 8px;
      bottom:-8px;
      border-bottom:8px solid transparent;
      border-right:8px solid #96b12e;
      left: 0;
     
    }
  }

  .card-title {
    padding-right: .28rem;
    margin-top: 1rem;
    height:.8rem;
    font-size: var(--font-size-l-m);
    line-height: 1.5;
    margin-bottom:.12rem;
  }
  .desc-info{
    line-height:.4rem;
    height:.3rem;
    font-size:var(--font-size-s-m);
    .author{
      background-size:.16rem .2rem;
      padding-left:.35rem;
    }
    .teacher{
      background-size:.16rem .2rem;
      padding-left:.35rem;
    }
    .from{
      background-size:.16rem .2rem;
      padding-left:.35rem;
    }
    .school{
      background-size:.16rem .2rem;
      padding-left:.35rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  
}
}
</style>