<template>
  <div class="page">
    <div class="banner" :style="{backgroundImage:'url('+bannerImg+')'}"></div>     

      <Menu :menuData="menuData"></Menu>
  </div>
</template>

<script>
import Menu from '../../components/Menu.vue'
export default {
  data () {
    return {
      menuData: [
        { link: '/dynamic/tzgg', name: '通知公告', id: 1 },
        { link: '/dynamic/hdbd', name: '活动报道', id: 3 },
        { link: '/dynamic/ndph', name: '年度排行', id: 2 },
      ],
      pageWidth: window.innerWidth
    }
  },
  components: {
    Menu
  },
  computed: {
    bannerImg () {
      const isMobile = window.innerWidth>798?'':'-m';
      let bannerName = '';
      switch (this.$route.path) {
        case '/dynamic/tzgg':
        bannerName = 'banner-hddt';
        break;
        case '/dynamic/ndph':
          bannerName = 'banner-ndph';
          break;
        case '/dynamic/hdbd':
          bannerName = 'banner-hd';
          break;

      }
      let fileName = bannerName + isMobile + '.jpg';
      return require('../../assets/images/dynamic/' + fileName);
    }
  },
}
</script>

<style lang="less" scoped>
.page {
  padding-bottom: 60px;
}
.banner{
  width: 100%;
  height: 400px;
  background-position:center;
  background-size: 1920px 100%;
  background-repeat: no-repeat;
}
/deep/.container {
  width: 890px;
  margin-left: 31px;
  padding-top: 55px;
  padding-bottom: 55px;
}


@media (max-width: 768px) {
  .banner {
    width: 7.5rem;
    height: 2.68rem;
    background-size:100% 100%;
  }
}
</style>  