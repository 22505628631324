<template>
  <div class="container">
      <div class="img-box">
        <router-link to="/question/admin">
        <img src="../../assets/images/participate/card-cjwt.png"/>
        </router-link>
        <router-link to="/guideline">
        <img src="../../assets/images/participate/card-hdzn.png"/>
        </router-link>
        <router-link to="/resource/yxal/js">
        <img src="../../assets/images/participate/card-hdzyb.png" />
        </router-link>
      </div>
      <div class="name"><img src="../../assets/images/participate/icon.png" />为什么要以学校名义注册参与活动？</div>
      <div class="info">
        <p>
          学校通过活动网站正式注册后就有可能免费获取主办单位提供的活动指南、资源包等活动资源，并推荐优秀学生小组、教师科技实践活动报告参与全国评选，同时参与全国优秀活动实施学校的评选。学校还可以提交相关活动信息和成果，并通过活动网站进行展示。
        </p>
      </div>

      <div class="name"><img src="../../assets/images/participate/icon.png" />学校如何注册？</div>
      <div class="info">
        <p>
          选择一名教师作为学校管理员，通过活动网站提交个人及学校信息，并在生成的申请表上盖章上传到活动网站即注册成功。活动小组的辅导教师与学校管理员可以为同一人。
        </p>
      </div>

      <div class="name"><img src="../../assets/images/participate/icon.png" />学校注册有时间限制吗？</div>
      <div class="info">
        <p>
          学校任何时间均可注册，没有时间限制。学校注册后即可参与当年的全国评选。学校第一次注册提交盖章申请表后，如第二年再次参与活动评选，通过学校管理员账号登录申请活动实施学校，不需要再次提交申请表。
        </p>
      </div>

      <div class="name"><img src="../../assets/images/participate/icon.png" />优秀活动实施学校的评选标准是什么？</div>
      <div class="info">
        <p>评选全国优秀活动实施学校时重点考察学校参与学生数量，通过网站提交的调查数据和活动成果的情况等。</p>
      </div>
      <div class="text-center">
        <div class="step1"></div>
        <div class="title-step"></div>
        <div class="focus-entrance">
          <div @click="jumpToSchool">
            <img src="../../assets/images/participate/xxczzn.png" class="show-lg"/>
            <img src="../../assets/images/participate/jszn-school-m.png" class="show-mobile"/>
          </div>
        </div>
        <div class="step2"></div>
      </div>
      <div class="school-rank text-center">
        <div>学校排名 (按照参与小组数由高到低)</div>
        <div class="show-mobile desc-to-pc">PC端访问查看完整榜单</div>
      </div>
      <table>
        <tr>
          <th>排名</th>
          <th>学校</th>
          <th>地区</th>
          <th>教师</th>
          <th>小组</th>
          <th>学生</th>
          <th>报告</th>
        </tr>
        <tr v-for="(item, index) in schoolList" :key="index">
          <td>
            <img src="../../assets/images/participate/1.png" v-if="item.rank == 1" />
            <img src="../../assets/images/participate/2.png" v-if="item.rank == 2" />
            <img src="../../assets/images/participate/3.png" v-if="item.rank == 3" />
            <span v-if="item.rank != 1 && item.rank != 2 && item.rank != 3">{{ item.rank }}</span>
          </td>
          <td>{{item.unit_name }}</td>
          <td>{{item.city_name }}</td>
          <td>{{ item.teacherNumber }}</td>
          <td>{{ item.groupNumber}}</td>
          <td>{{ item.studentNumber }}</td>
          <td>{{ item.groupNumber }}</td>
        </tr>
      </table>

  </div>
</template>

<script>
import { getSchoolTopRankNew } from '../../api/api'
export default {
  data () {
    return {
      schoolList: []
    }
  },
  created () {
    this.getSchoolList()
  },
  methods: {
  
    jumpToSchool () {
      this.$router.push('/article/1093')
    },
    async getSchoolList () {
      const res = await getSchoolTopRankNew()
      this.schoolList = res.data
      this.schoolList.forEach((item, index) => {
        item.rank = index + 1
      })
    }
  }
}
</script>

<style scoped lang="less">
.container {
    .text-center {
      .step1 {
        width: 100%;
        height: 128px;
        background: url('../../assets/images/participate/step-school.png') no-repeat center;
        background-size: 100% 100%;
      }
      .focus-entrance {
        width: 100%;
        margin: 55px auto;
        cursor: pointer;
      }
      .step2 {
        width: 100%;
        height: 582px;
        background: url('../../assets/images/participate/step-s.jpg') no-repeat center;
        background-size: 100% 100%;
        margin-bottom:50px;
      }
    }
    table {

      width: 100%;
      margin-top: 30px;
      text-align: center;
      th {
        background: #eef7fe;
        font-size: 16px;
        color: rgb(84, 148, 210);
        line-height: 2;
        text-align: center;
      }
      tr {
        width: 100%;
        border-bottom: 1px dotted #e8e8e8;
        height: 50px;
      }
    }
  
}
@media (max-width: 768px) {
  .container {
    .text-center {
      .step1 {
        height: 3.62rem;
        background: url('../../assets/images/participate/step-school-m.png') no-repeat center;
        background-size: 100% 100%;
      }
      .focus-entrance {
          width: 100%;
          margin: .8rem auto;
          img {
          max-width: 100%;
          margin: 0 auto;
        }
        }
      .step2 {
        height: 15.22rem;
        background: url('../../assets/images/participate/step.png') no-repeat center;
        background-size: 100% 100%;
        margin-bottom:.5rem;
      }
    }
    .desc-to-pc{
      color:#666;
      margin-top:.2rem;
      font-style: italic;
    }
    table {
      margin-top: .3rem;
      th {
        white-space: nowrap;

        font-size: .28rem;
        font-weight: bold;
        &:nth-child(4),&:nth-child(5),&:nth-child(6),&:nth-child(8){
            display:none;
          }
      }
      tr {

        height: 1.2rem;
       td{
        &:nth-child(4),&:nth-child(5),&:nth-child(6),&:nth-child(8){
            display:none;
          }
       }
      }
    }
  
}
}
</style>