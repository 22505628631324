<template>
  <div class="table-box">
    <table>
      <tr>
        <th v-for="(item, index) in headList" :key="index">{{ item }}</th>
      </tr>
      <tr v-for="(item, index) in dataList" :key="index">
        <td>
          <img src="../../assets/images/participate/1.png" v-if="item.rank == 1" />
          <img src="../../assets/images/participate/2.png" v-if="item.rank == 2" />
          <img src="../../assets/images/participate/3.png" v-if="item.rank == 3" />
          <span v-if="item.rank != 1 && item.rank!= 2 && item.rank != 3">{{ item.rank }}</span>
        </td>
        <td v-if="item.rankName=='city'">{{item.provinceName}}</td>
        <td>{{item.rankName=='province'? item.provinceName:item.rankName=='city'?item.cityName:item.rankName=='school'?item.schoolName:item.cityName }}</td>
        <td v-if="item.rankName=='province' || item.rankName=='city'">{{item.rankName=='province'?item.schoolNumber:item.rankName=='city'?item.schoolCount: item.schoolNumber}}</td>
        <td>{{ item.rankName=='province' || item.rankName=='city'? item.studentNumber:item.studentCount }}</td>
        <td>{{ item.rankName=='school' || item.rankName=='city' ?item.teamCount: item.groupNumber}}</td>
        <td>{{  item.rankName=='province' || item.rankName=='city' ?item.teacherNumber:item.teacherCount }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      name: 'Table',
    }
  },
  props: {
    dataList: {
      type: Array,
      require: true
    },
    headList: {
      type: Array,
      require: true
    }
  },
}
</script>

<style scoped lang="less">
table {
  width: 890px;
  margin-top: 37px;
  text-align: center;
  & tr:nth-child(1) {
    background: #eef7fe;
  }
  th {
    font-size: 16px;
    color: rgb(84, 148, 210);
    line-height: 2;
    text-align: center;
    white-space: nowrap;
    &:first-of-type{
          width: 50px;
        }
  }
  tr {
    width: 100%;
    border-bottom: 1px dotted #e8e8e8;
    height: 50px;
  }
}
@media (max-width: 768px) {
  .table-box {
    width: 100%;
    overflow-x: scroll;
    padding-bottom: .2rem;
    table {
      width: 10rem;
      margin-top: 0.44rem;
      th {
        font-size: 0.2rem;
        &:first-of-type{
          width: 0.6rem;
        }
      }
      tr {
        width: 100%;
        border-bottom: 1px dotted #e8e8e8;
        font-size: 0.2rem;
        height: 0.5rem;
        img {
          width: 0.3rem;
        }
      }
    }
  }
}
</style>