<template>
  <div class="page-with-menu ">
      <BreadCrumb :crumbData="breadCrumb"></BreadCrumb>
      <div class="list-title">{{zybName}}</div>
      <div v-for="item in zybData" class="list-item">
        {{item.title}}
      
          <a class="btn-oper download"  v-if="item.type=='pdf'" :href="item.url">下载</a>
          <router-link class="btn-oper video"  v-if="item.type=='mp4'" :to="'/guideline/zyb/list/'+zybId+'/'+item.id">播放</router-link>
  </div>
</div>
</template>

<script>
import DataList from '@/components/DataList/DataList.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';
import {getResourceListById} from '../../api/api'
export default {
  components: {
    DataList,
    BreadCrumb
},
  data(){
    return {
      // 顶部面包屑
      breadCrumb:[
       {name:'活动指南',url:'/#/guideline/Nyzy'},{name:'相关资源'}
      ],
      // 资源包id
      zybId:null,
      // 资源包名称（活动名称）
      zybName:null,
      // 资源包数据列表
      zybData:[]
     
    }
  },
  mounted(){
    this.zybId = this.$route.params.id;
    // 根据zybId，获取对应资源包数据，初始化页面
    this.fetchData(this.zybId);
  },
  methods:{
    // 根据zybId，获取对应资源包数据，初始化页面
    async fetchData(zybId){
      this.zybName = '相关资源';
      // type有pdf、mp4，视频跳转到视频播放页面，文档直接下载
      const zybData = await getResourceListById(zybId);
       this.zybData = zybData.data.list;
       console.log(zybData.data);
    }
  }
}
</script>

<style scoped lang="less">
  .page-with-menu {
    padding-top: 55px;
    padding-left:30px;
  }
  .list-title{
    font-size:18px;
    font-weight: bold;
    text-align:center;
    margin:20px auto
  }
  .list-item{
    height:54px;
    display:block;
    border:1px solid #D0D3D5;
    line-height:54px;
    padding:0 25px;
    margin-bottom:-1px;

    .btn-oper{
      font-size:16px;
      float:right;
      height:30px;
      line-height:30px;
      background-repeat:no-repeat;
      background-position:right center;
      padding-right:25px;
      color:#61abe0;
      margin-top:12px;
      &.download{
        background-image:url('../../assets/images/resource/download@2x.png');
        background-size:18px 17px;
      }
      &.video{
        background-image:url('../../assets/images/resource/video@2x.png');  
        background-size:16px 16px;
      }
    }
  }
 
  @media (max-width: 798px) {
    .page-with-menu {
      padding-top: .3rem;
      padding-left:0;
    }

    .list-title{
    font-size:.32rem;

    margin:.3rem auto;
  }
  .list-item{
    height:1rem;
    line-height:1rem;
    padding:0 .28rem;
    margin-bottom:-1px;

    .btn-oper{
      font-size:.28rem;
      
      height:.5rem;
      line-height:.5rem;
      padding-right:25px;
      color:#61abe0;
      margin-top:.25rem;
      &.download{
        background-image:url('../../assets/images/resource/download@2x.png');
        background-size:18px 17px;
      }
      &.video{
        background-image:url('../../assets/images/resource/video@2x.png');  
        background-size:16px 16px;
      }
    }
  }


  }
</style>