import CookieUtil from "./cookie";
const baseURL = process.env.VUE_APP_MOLAWARE;

/* MOLAWARE SIMPLEST TRACKING SERVICE */
const trackApi =baseURL+ 'log/addPortalLog?';
/**
 * 基础追踪模块
 * @param {string} path 页面路径，使用传入的或者location.path 
 * @param {string} url 页面地址，使用传入的或者location.href 
 * @param {string?} pathName 路径名称，使用传入的
 * @param {string?} ext1 额外属性1，在当前项目中，传入模块名称
 * @param {string?} ext2 
 * @param {string?} ext3 
 * @param {string?} ext4 
 * @param {string?} ext5 
 */
export function MolaTrack(path,url,pathName,ext1,ext2,ext3,ext4,ext5) {
  let arr = [];
  // 尝试获取用户cookie.
  let userCookie = CookieUtil.get('MT__GUR');
  if(!userCookie){
    // 生成一组随机数.
    userCookie = 'MT__GUR'+Math.random();
    let now = new Date();
    let time = now.getTime()
    let expireTime = time + 1000 * 3600;
    now.setTime(expireTime);
    CookieUtil.set('MT__GUR',userCookie,expireTime);
  }
  arr['MT_GUR'] = userCookie;
  arr['p'] =  path || location.path;
  arr['u'] =  url || location.href;
  arr['n']= pathName || null;
  arr['e1']= ext1 || null;
  arr['e2']= ext2 || null;
  arr['e3']= ext3 || null;
  arr['e4']= ext4 || null;
  arr['e5']= ext5 || null;
  let result = []
  for(let item in arr){
   
    if(arr[item]){
      result.push(item+'='+encodeURIComponent(arr[item]));
    }
  }

  const paramString =  result.join('&');
  const requestUrl = trackApi+paramString;
  let img = new Image();
  img.src=requestUrl;

}