<template>
  <div :class="'nav-container ' + (isOpen ? 'active' : '')">
    <router-link class="menu-item" to="/home">首页</router-link>
    <router-link class="menu-item" to="/participate" 
      >参与活动
      <div class="sub-menu-container" @click="handleSubMenuItemClick">
        <ul class="sub-menu">
          <li><router-link class="sub-menu-item"  to="/participate/about">关于活动</router-link></li>
          <li><router-link class="sub-menu-item" to="/participate/join">如何参与</router-link></li>
          <li><router-link class="sub-menu-item" to="/participate/czzn">参与指南</router-link></li>
          <li><router-link class="sub-menu-item" to="/participate/contact">联系方式</router-link></li>
        </ul>
      </div>
    </router-link>
    <router-link class="menu-item" to="/guideline">活动指南</router-link>
    <router-link class="menu-item" to="/dynamic"
      >活动动态
      <div class="sub-menu-container" @click="handleSubMenuItemClick">
        <ul class="sub-menu">
          <li><router-link class="sub-menu-item" to="/dynamic/tzgg">通知公告</router-link></li>
          <li><router-link class="sub-menu-item" to="/dynamic/hdbd">活动报道</router-link></li>
          <li><router-link class="sub-menu-item" to="/dynamic/ndph">年度排行</router-link></li>
        </ul>
      </div>
      
    </router-link>
    <router-link class="menu-item" to="/resource"
      >案例资源
      <div class="sub-menu-container" @click="handleSubMenuItemClick">
        <ul class="sub-menu">
          <li><router-link class="sub-menu-item" to="/resource/jskc">教师课程</router-link></li>
          <li><router-link class="sub-menu-item" to="/resource/yxal">活动案例</router-link></li>
        </ul>
      </div>
    </router-link>
    <router-link class="menu-item" to="/question"
      >常见问题
    </router-link>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  props: {
    isOpen: Boolean
  },
  data () {
    return {

    }
  },
  watch: {
    $route: {
      handler () {
        this.$emit('isMenuClose', false)
      }
    }
  },
  methods: {
   // 子菜单点击后，隐藏的相关处理
   handleSubMenuItemClick (evt) {
      const argItem = $(evt.srcElement)
      if (argItem.is('.sub-menu-item')) {
        // 强制关闭菜单。
        const parentMenuItem = argItem.parents('.menu-item')
        parentMenuItem.css('overflow', 'hidden')
        window.setTimeout(() => { parentMenuItem.css('overflow', '') }, 50)
      }
    },
  }


}
</script>

<style scoped lang="less">


.menu-item{
  font-size: 18px;
  border-top: 0px solid transparent;
  display: inline-block;
  min-width: 74px;
  height: 60px;
  color: #666666;
  margin-right: 34px;
  line-height: 57px;
  position: relative;
  text-align: center;
  overflow:hidden;
  vertical-align: top;
  transition:border .5s;
  
  &::after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 4px;
    background: transparent;
    transform: scaleX(0);
    transition: all .2s;
    
  }
  &.router-link-active {
    // border-top: 4px solid var(--main-color-1);
    font-weight: bold;
    color:#333;

    &::after{
      transform: scaleX(1);
    background: var(--main-color-1);

   
  }
  }
  &:hover {
    color: #333;
    overflow:visible;
    .sub-menu{
      opacity:1;
      transform: translateY(0px);
      font-weight: normal;
    }
  }

}

.sub-menu-container{
  position: absolute;
  left:-25px;
  right:-25px;
  top:61px;

}
.sub-menu {
  opacity:0;
  background: #fff;
  padding: 0;
  transform: translateY(-15px);
  transition-delay: 0.3s;
  transition:all 0.2s;
  overflow: visible;
  box-shadow:none;
  
  .router-link-active {
    border: none;
    color:#5293D2;
  }

  li {
    white-space: nowrap;
    line-height: 50px;
    padding: 0;
    &:hover {
      background: #eee;
    }
    a {
      color: #666;
      background: none;
      min-width: auto;
      margin-right: unset;
      display: block;
      line-height: unset;
    }
  }
}
.nav-container a:hover .sub-menu {
  opacity:1;
  box-shadow:inset 0 10px 10px 0px #eee;
  color: var(--main-color-1) !important;
}

@media (max-width: 768px) {
  .router-link-active {
    color: #333 !important;
    background-size: 0.79rem 0.12rem;
    font-size: 1.2em !important;
    font-weight: bold;
  }
  .menu-item{
    display: block;
    line-height: 1rem;
    height: 1rem;
    margin: 0 0.4rem;
    font-size: var(--font-size-l-m);
    &.router-link-active{
      border:0;
    }
    &::after{
      display: none;
    }
  }

  .nav-container {
    width: 100%;
    min-width: auto;
    margin: 0 auto;
    display: none;
    position: absolute;
    background: #fff;
    top: 100%;
    left: 0;
    opacity: 0.9;
    box-shadow: 0 20px 30px 3px rgba(231, 231, 231, 0.3);
    padding-bottom: 0.2rem;
  }
  .nav-container.active {
    display: block;
  }

  .nav-container a .sub-menu {
    display: none !important;
  }

}
</style>