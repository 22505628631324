<template>
  <div class="page">
      <div class="banner" :style="{backgroundImage:'url('+bannerImg+')'}"></div>     
      <Menu :menuData="menuData" ></Menu>  
  </div>
</template>

<script>
import Menu from '../../components/Menu.vue'
export default {
  data () {
    return {
      menuData: [
        { link: '/participate/about', name: '关于活动', id: 1 },
        {link:  '/participate/join',name:'如何参与',id:5},
        { link: '/participate/join/student', name: '学生', id: 3 ,parent:'如何参与'},
        { link: '/participate/join/teacher', name: '教师', id: 2 ,parent:'如何参与'},
        { link: '/participate/join/school', name: '学校', id: 4,parent:'如何参与' },
        {link:'/participate/czzn',name:'参与指南'},
        { link: '/participate/czzn/workflow', name: '总体流程', id: 6 ,parent:'参与指南'},
        { link: '/participate/czzn/manager', name: '活动管理员', id: 7 ,parent:'参与指南'},
        { link: '/participate/czzn/counsellor', name: '辅导教师', id: 8,parent:'参与指南' },
        { link: '/participate/czzn/studentGroup', name: '学生小组', id: 9,parent:'参与指南' },
        { link: '/participate/czzn/question', name: '常见问题', id: 10,parent:'参与指南' },
        {link:'/participate/contact',name:'联系方式'}
      ],
      pageWidth: window.innerWidth
    }
  },
  components: {
    Menu
  },
  computed: {
    bannerImg () {
      const isMobile = window.innerWidth>798?'':'-m';
      let bannerName = '';
      switch (this.$route.path) {
        case '/participate/about':
        bannerName = 'banner-cyhd';
        break;
        case '/participate/join/teacher':
        case '/participate/join/teacher/jyfx':
          bannerName = 'banner-cyhd-js';
          break;
        case '/participate/join/student':
          bannerName = 'banner-cyhd-xs';
          break;
        case '/participate/join/school':
          bannerName = 'banner-cyhd-xx';
          break;
        case '/participate/czzn':
        case '/participate/czzn/workflow':
        case '/participate/contact':
        case '/participate/czzn/manager':
        case '/participate/czzn/counsellor':
        case '/participate/czzn/question':
        case '/participate/czzn/studentGroup':
          bannerName = 'banner-cyzn';
          break; 
      }
      let fileName = bannerName + isMobile + '.jpg';
     
      return require('../../assets/images/participate/' + fileName);
    }
  },
}
</script>

<style lang="less" scoped>
.page {
  padding-bottom: 60px;
  /* 当前participate包含的4个子分类样式定义 */
  /deep/.container {
  width: 890px;
  margin-left: 31px;
  padding-top: 55px;
  padding-bottom: 55px;
  .img-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 60px;
      img {
        width: 292px;
        height: 120px;
        cursor: pointer;
      }
    }
    .name {
      font-size: 22px;
      font-weight: 600;
      color: var(--main-color-1);
      line-height: 32px;
      margin-bottom:20px;
      img {
        display: inline-block;
        width: 26px;
        height: 45px;
        margin-right: 5px;
        vertical-align: middle;
      }
    }
    .info {
      font-size: 16px;
      color: #333333;
      padding-left: 0;
      line-height: 32px;
      p {
        // margin-bottom: 3em;
        text-indent:2em;
        line-height: 36px;
        padding-bottom: 20px;
        font-size: 16px;
      }
      .sub-title {
        font-weight: 600;
        color: #333333;
      }
    }
    video {
      width: 100%;
    }
  }
}
.banner{
  width: 100%;
  height: 400px;
  background-position:center;
  background-size: 1920px 100%;
  background-repeat: no-repeat;
}


@media (max-width: 768px) {
  .page {
  padding-bottom: 1rem;
  /* 当前participate包含的4个子分类样式定义 */
  /deep/.container {
  width: 100%;
  margin-left: 0;
  padding-top: 1rem;
  padding-bottom: .2rem;
  .img-box {
      margin-bottom: .3rem;
      img {
        width: 2.25rem;
        height: .94rem;
      }
    }
    .name {
      font-size: .34rem;
      line-height: .4rem;
      margin-bottom:0.1rem;
      img {
        width: .38rem;
        height: .66rem;
        margin-right: .08rem;
      }
    }
    .info {
      font-size: .28rem;
      line-height: 1.8;
      font-size: var(--font-size-l-m);;
      p {
        // margin-bottom: 2em;
        line-height: 1.8;
        padding-bottom: 0.4rem;
      }
    }
  }
}
  .banner {
    width: 7.5rem;
    height: 2.68rem;
    background-size:100% 100%;
  }
}
</style>  