<template>
    <div>
       <Hdzn :type="1"></Hdzn>
    </div>
</template>
<script>
import Hdzn from './Hdzn.vue'
export default {
    components:{
        Hdzn
    },
    data(){
        return{

        }
    }
}
</script>