<template>
  <div class="card-simple">
    <a :href="cardData.url" target="_blank">
      <div class="img-container">
        <img :src="cardData.image" alt="" />
        <div v-if="showView" class="view-count">
          {{ cardData.readCount }}
          <div class="watch"></div>
        </div>
        <div :class="'content-type '+contentType" v-if="contentType"></div>
      </div>
      <div class="card-title">{{ cardData.title }}</div>
      <div v-show="showDate" class="duration-date">{{ durationDate }}</div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'CardItem',
  props: {
    cardData: {
      type: Object,
      require: true
    },
    showDate: {
      type: Boolean,
      default: true
    },
    showView:{
      type:Boolean,
      default:true
    },
    // 卡片对应的类型。如下：article,photo,video
    contentType:{
      type:String,
      default:''
    }

  },
  data () {
    return {
      
    }
  },
  methods: {

  },
  computed:{
    durationDate(){
      let startDate = new Date(this.cardData.startTime.replace(' ','T'));
      let endDate = new Date(this.cardData.endTime.replace(' ','T'));
      // 时间归零
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      endDate.setHours(0);
      endDate.setMinutes(0);
      endDate.setSeconds(0);
      if(startDate.getTime() === endDate.getTime()){
        return startDate.getFullYear() + '年' + (startDate.getMonth() + 1) + '月' + startDate.getDate() + '日';
      }
      else{
        return startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate() + '' + ' 至 ' + endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate() + '';
      }
      
    }
  }
}
</script>

<style scoped lang="less">
.card-simple {
  transition:all .2s;
  position: relative;
  width: 277px;
  height: 270px;
  background: #fff;
  overflow: hidden;
  border-radius: 0;
  box-shadow: 0px 7px 24px 0px rgba(136, 136, 136, 0.17);
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 25px;
  &:nth-of-type(3n) {
    margin-right: 0;
  }
  .img-container {
    width: 277px;
    height: 157px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }

  }
  .card-title {
    padding-left: 15px;
    padding-right: 26px;
    margin-top: 20px;
    font-size: var(--font-size-m);
    color: #111;
    line-height: 26px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .duration-date {
    position: absolute;
    font-size: var(--font-size-s);
    color: #999;
    left: 15px;
    bottom: 12px;
    background:url(../../assets/images/time.jpg) no-repeat left center;
    padding-left:20px;
  }
  &:hover {
    transform:translateY(-10px);
    box-shadow: 0px 10px 24px 0px rgba(105, 105, 105, 0.17);
  }
}

@media (max-width: 768px) {
  .card-simple {
    width: 3.29rem;
    height: 3.3rem;
    margin: 0.12rem 0rem;
    margin-right: .282rem;
    border-radius:0;
    &:nth-of-type(2n+1) {
      margin-right: .282rem;
    }
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    
    .img-container {
      width: 3.29rem;
      height: 1.85rem;
      img {
        width: 100%;
        height: 100%;
        margin: 0rem 0rem 0.1rem 0rem;
      }
    }
    .card-title {
      margin: 0.15rem 0.21rem 0.19rem 0.12rem;
      padding: 0;
      line-height: 0.4rem;
      font-size: var(--font-size-l-m);
      color: #111;
    }
    .duration-date {
      font-size: var(--font-size-s-m);
      left: 0.13rem;
      bottom: 0.07rem;
      background:none;
      padding-left:0;
    }

    
 
    &:hover {
      transform:none;
      box-shadow: 0px 7px 24px 0px rgba(136, 136, 136, 0.17);
    }
  }
}
</style>