<template>
  <div class="page">
    <div class="banner"></div>
    <BreadCrumb :crumbData="breadCrumb" class="article-breadcrumb main-width-container"></BreadCrumb>
    <div class="article-container main-width-container" v-if="articleData">
      <div class="article-title">{{ articleData.title }}</div>
      <div class="article-info">
        <div class="create-time">{{ articleData.publishTime }}</div>
        <div class="author">{{ articleData.sourceName }}</div>
      </div>
      <div class="article-body" v-html="articleData.content"></div>
      <div class="file" v-if="articleData.fileList && articleData.fileList.length != 0">
        附件:
        <a
          :href="item.url"
          v-for="(item, index) in articleData.fileList"
          :key="index"
          style="margin-top: 10px; color: #25a4bb; padding-left: 25px"
          >{{ item.name }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getDynamicDetail } from '../api/api'
import BreadCrumb from '../components/BreadCrumb'
export default {
  data () {
    return {
      articleData: null,
      id: this.$route.params.id,
      breadCrumb:[],
    }
  },
components: {
    BreadCrumb
  },
  created () {
    this.getDetail();

  },
  mounted(){
    //获取面包屑
    let breadCrumb = sessionStorage.getItem('breadCrumb');
    if(breadCrumb){
      this.breadCrumb = JSON.parse(breadCrumb);
    }
    //id为1091时不显示面包屑
    if(this.id==1091){
      this.breadCrumb = [];
    }
  },
  beforeRouteEnter(to,from,next){
    next(_this=>{
      if(!from || !from.name){
        _this.breadCrumb = [];
        return;
      }
      _this.breadCrumb = [{name:from.name,url:'/#'+from.path},{name:to.name}];
     //存储面包屑
      sessionStorage.setItem('breadCrumb',JSON.stringify(_this.breadCrumb));
    });
    if(to.params.id==1091){
      //不显示面包屑
      next(_this=>{
        _this.breadCrumb = [];
      });

    }
  },
  methods: {
    // 根据id获取详情
    async getDetail () {
      const res = await getDynamicDetail(this.id)
      this.articleData = res.data
    }
  },
}
</script>


<style scoped lang="less">
.page {
  /deep/ a{
   color:  var(--main-color-1);
  }
  /deep/.title{
    width:inherit;
    display:block;
  }
  .article-breadcrumb {
    margin-top:15px;
  }

 
  background: url('../assets/images/Home/bg.png') no-repeat bottom center #fff;
  .banner {
    width: 100%;
    height: 400px;
    background-position: center;
    background-size: 1920px 100%;
    background-repeat: no-repeat;
    background-image: url('../assets/images/guideline/banner.jpg');
  }
  .article-container {
    margin-top: 50px;

    .article-title {
      font-size: 24px;
      color: #111111;
      line-height: 1.8;
      text-align: center;
      font-size: var(--font-size-xl);
      margin-bottom: 14px;
      padding: 0 75px;
    }
    .article-info {
      text-align: center;
      color: #999;
      margin-bottom: 50px;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;

      div {
        display: inline-block;
        margin: 0 10px;
        letter-spacing: 1px;
      }
    }

    .article-body {
      color: #333;
      // font-size: var(--font-size-l);
      font-size: var(--font-size-m);
      line-height: 26px;
      font-family: Microsoft YaHei;
      letter-spacing: 0;
      text-align: justify;
      padding: 0 75px;
      /deep/ img {
        display: block;
        max-width: 100%;
        margin: 0px auto;
      }
      /deep/ p{
        line-height: 36px;
        padding-bottom: 20px;
      }
    }
    .file {
      color: #333;
      font-size: var(--font-size-l);
      margin-top: 10px;
    }
  }
}

@media (max-width: 768px) {
  /deep/table{
    width:100%;
  }
  .page {
  .banner {
    width: 100%;
    height: 2.68rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('../assets/images/dynamic/banner-hd-m.jpg');
  }
  .article-container {
    margin-top: .4rem;

    .article-title {
      font-size: .34rem;
      line-height: 1.5;
      margin-bottom: .2rem;
      padding: 0;
    }
    .article-info {

      margin-bottom: .4rem;
      border-bottom: 1px solid #eee;
      padding-bottom: .3rem;

      div {
        display: inline-block;
        margin: 0 .2rem;
      }
    }

    .article-body {

      font-size: var(--font-size-l-m);
      line-height: 1.8;
      padding: 0;
      /deep/ img {
        margin: 0rem auto;
        width:auto !important;
        height:auto !important;
      }
      /deep/ p{
        line-height: 1.8;
        padding-bottom: 0.4rem;
      }
      
    }
    .file {

      font-size: var(--font-size-l-m);
      margin-top: .2rem;
    }
  }
}
}
</style>