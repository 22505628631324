<template>
  <div class="page">
    <div class="banner">
      <swiper ref="bannerSwiper" v-if="bannerSwiperData.length" :options="swiperOption" class="top-banner-swiper">
        <!-- slides -->
        <swiper-slide v-for="(item, index) in bannerSwiperData" :key="index">
          <a :href="item.url" target="_blank">
            <img :src="item.image" />
          </a>
        </swiper-slide>
      </swiper>
        <!-- 左右箭头 -->
        <div class="nav-controller nav-prev"><i class="el-icon-arrow-left"></i></div>
         <div class="nav-controller nav-next"><i class="el-icon-arrow-right"></i></div>
      <div class="banner-progress"></div>
    </div>

    <div class="main-width-container">
      <div class="intro-container">
        <router-link  to="/participate/czzn/workflow" class="intro-item hdzn">
            <div >
            <div class="intro-title">线上参与</div>
            <div class="intro-desc">
              如何注册参与并提交成果

            </div>
            <a href="" class="btn-to-detail">
              <div class="btn-arrow"></div>
            </a>
          </div>
          </router-link>

          <router-link to="/resource/yxal/js" class="intro-item hdal">
            <div>
              <div class="intro-title">活动案例</div>
              <div class="intro-desc">
                学生和教师活动报告案例
              </div>
              <a href="" class="btn-to-detail">
                  <div class="btn-arrow"></div>
               </a>
            </div>
            </router-link>
     
            <router-link to="/resource/jskc"  class="intro-item jskc">
              <div>
                <div class="intro-title">教师课程</div>
                <div class="intro-desc">
                  教师线上课程资源
                </div>
                <a href="" class="btn-to-detail">
                  <div class="btn-arrow"></div>
               </a>
              </div>
            </router-link>

            <router-link to="/dynamic/ndph" class="intro-item xscy">
            <div>
              <div class="intro-title">参与排行</div>
              <div class="intro-desc">
                参与地区和学校排行
              </div>
              <a href="" class="btn-to-detail">
                <div class="btn-arrow"></div>
              </a>
            </div>
          </router-link>
     
      </div>
      <div class="focus-entrance">
        <a href="https://sciencedayuser.cyscc.org">
          <img src="../../assets/images/Home/cyhd.png" class="show-lg"/>
          <img src="../../assets/images/Home/cyhd-m.jpg" class="show-mobile"/>
        </a>
      </div>
      <div class="participate-map-container">
        <div class="map-container">
          <Map @countChange="countChange"></Map>
        </div>
        <div class="participate-data-container">
          <div class="item school">
            <div class="title">学校</div>
            <div class="data">{{mapCountData.school}}</div>
          </div>
          <div class="item group">
            <div class="title">小组</div>
            <div class="data">{{mapCountData.group}}</div>
          </div>
          <div class="item teacher">
            <div class="title">教师</div>
            <div class="data">{{mapCountData.teacher}}</div>
          </div>
          <div class="item student">
            <div class="title">学生</div>
            <div class="data">{{mapCountData.student}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="news-block">
      <div class="main-width-container">
        <DataList
          class="card-list"
          v-if="cardListData"
          :dataList="cardListData"
          :totalCount="cardListData.length"
          :hasPagination="false"
          showType="CardList"
          showSubType="cardListItemHome"
        ></DataList>

        <DataList
          class="news-list clearfix"
          v-if="newsListData"
          :dataList="newsListData"
          :totalCount="newsListData.length"
          :hasPagination="false"
          showType="CardList"
          showSubType="NewsListItemHome"
        ></DataList>

        <router-link to="/dynamic/tzgg" class="btn">查看更多</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Map from './Map.vue'
import DataList from '@/components/DataList/DataList.vue'
import { getSwiperList, getActivityData } from '../../api/api'
export default {
  components: {
    Map, DataList
  },
  data () {
    return {
      // 首页地图下方的3个定制卡片
      cardListData: [],
      // 首页最下方的新闻列表6条
      newsListData: [],
      bannerSwiperData: [],
      swiperOption: {
        loop: true,
        autoplay: {
          delay:2500
        },
        //左右箭头
        navigation: {
          nextEl: '.nav-next',
          prevEl: '.nav-prev'
        },
        
        pagination: {
          el: '.banner-progress',
          clickable: true
        },
        paginationClickable: true,
        on:{
            init:()=>this.swiperPauseOnHover(this.$refs.bannerSwiper)
        }

      },
      // 地图右侧的4个统计数据
      mapCountData:{
        teacher: 0,
        student: 0,
        school: 0,
        group: 0
      }
    }
  },
  created () {
    this.getSwiper()
    this.getDynamicData()
  },
  methods: {
    
    swiperPauseOnHover(vueRefSwiper){
        const thisSwiper = vueRefSwiper;

        thisSwiper.$el.onmouseover = function(){
        thisSwiper.swiper.autoplay.stop();
        }
        thisSwiper.$el.onmouseout = function(){
        thisSwiper.swiper.autoplay.start();
        }
    },
    // 获取轮播数据
    async getSwiper () {
      const res = await getSwiperList()
      this.bannerSwiperData = res.data
    },
    // 活动动态数据
    async getDynamicData () {
      const res = await getActivityData()
      this.cardListData = res.data.imageNewsList
      this.newsListData = res.data.textNewsList
    },
    // 地图数据更新
    countChange(data){
      this.mapCountData = {
        teacher: data.teacherCount, 
        student: data.studentCount, 
        school:data.schoolCount, 
        group: data.teamCount
      };
    }
  }

}
</script>

<style scoped lang="less">
@font-face {
  font-family: NewYork;
  src: url('../../assets/fonts/NewYork.otf');
}
.page {
  padding-bottom: 0;
  .banner {
    position: relative;
    height: 635px;
    .nav-controller{
      position: absolute;
      width: 50px;
      height: 50px;
      margin-top: -20px;
      top: 50%;
      z-index: 3;
      font-size: 30px;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      text-align: center;
      line-height: 50px;
      color: #fff;
      opacity: 0;
      transition: all 0.3s;
      cursor: pointer;
    &.nav-prev{
    left:10px;
    transform:translateX(-10px);
    }
    &.nav-next{
    right:10px;
    transform:translateX(10px);
    }
  }
  &:hover .nav-controller{
    transform:translateX(0);
    opacity:1;
  }
  }
}
.top-banner-swiper {
  max-width: 1920px;
  height: 635px;
  .swiper-slide {
    overflow: hidden;
    img {
      display: block;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: 1920px;
    }
  }
}

.banner-progress {
  text-align: center;
  z-index: 10;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  /deep/.swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 50%;
    margin-right: 30px;
    opacity: 0.4;
  }
  /deep/.swiper-pagination-bullet-active {
    opacity: 1;
  }
}
.intro-container {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  margin-bottom: 50px;
  gap: 45px;
  .intro-item {
    background-size: 260px 200px;
    background-repeat: no-repeat;
    background-position: top center;
    // height: 350px;
    padding-top: 135px;
    position: relative;
    width:25%;
    &.hdzn {
      background-image: url('../../assets/images/Home/card-1.png');
    }
    &.xscy {
      background-image: url('../../assets/images/Home/card-2.png');
    }
    &.hdal {
      background-image: url('../../assets/images/Home/card-3.png');
    }
    &.jskc {
      background-image: url('../../assets/images/Home/card-4.png');
    }
    .intro-title {
      text-align: center;
      line-height: 50px;

      font-size: 22px;
      font-weight: bold;
      color: #111;
      letter-spacing: 3px;
      &::after {
        content: '';
        display: block;

        width: 20px;
        height: 3px;
        background: #8b9aab;
        margin: 0 auto;
        border-radius: 2px;
      }
    }
    .intro-desc {
      color: #666;
      line-height: 1.8;
      font-size: 14px;
      margin-top: 30px;
      padding: 0 20px;
      margin-bottom: 40px;
      text-align: center;
    }
    .btn-to-detail {
      display: block;

      width: 90px;
      height: 38px;
      margin: 0 auto;
      border: 2px solid #d5d8da;
      border-radius: 20px;
      position: relative;
      transition: all 0.2s;
      .btn-arrow {
        background-image: url('../../assets/images/Home/btn-arrow.png');
        width: 100%;
        height: 7px;
        background-repeat: no-repeat;
        background-position: center -7px;
        background-size: 23px 14px;
        margin: 12px auto 0;
      }
      @keyframes btn-arrow {
        0% {
          background-position: center -7px;
        }
        50% {
          background-position: 95px -7px;
          opacity: 0.3;
        }
        50.1% {
          background-position: -95px 0px;
          opacity: 0.3;
        }
        100% {
          background-position: center 0px;
          opacity: 1;
        }
      }

      &:hover {
        background: #3b93d7;
        border-color: #3b93d7;
        .btn-arrow {
          background-position: 0 0;
          animation: btn-arrow 0.4s ease-out both;
        }
      }
    }
  }
}
.focus-entrance {
  text-align: center;
  margin-bottom: 50px;
  img {
    max-width: 100%;
    margin: 0 auto;
  }
}
.participate-map-container {
  display: flex;
  gap: 20px;
  margin-bottom: 80px;
  .map-container {
    width: 100%;
    margin-right: 20px;
  }
  .participate-data-container {
    width: 280px;
    flex-shrink: 0;
    justify-content: space-between;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .item {
      background-repeat: no-repeat;
      background-position: 20px 25px;
      position: relative;
      box-sizing: border-box;
      padding: 30px 0 30px 0;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        background-image: url('../../assets/images/Home/border-bottom.png');
        background-size: 100% 100%;

        height: 2px;
      }
      &.school {
        background-image: url('../../assets/images/Home/icon-xx.png');
      }
      &.group {
        background-image: url('../../assets/images/Home/icon-xz.png');
      }
      &.teacher {
        background-image: url('../../assets/images/Home/icon-js.png');
      }
      &.student {
        background-image: url('../../assets/images/Home/icon-xs.png');
      }
      .title {
        white-space: nowrap;
        margin: 0 0 0 105px;
        font-size: 22px;
        height: auto;
        width: auto;
        line-height: 1.2;
      }
      .data {
        white-space: nowrap;
        margin: 0 0 0 105px;
        font-size: 30px;
        letter-spacing: 1px;
        color: #5293d2;
      }
    }
  }
}
.news-block {
  padding-top: 50px;
  background-image: url('../../assets/images/Home/bg.png');
  background-position: bottom center;
  background-repeat:no-repeat;
  padding-bottom: 150px;
}

/deep/.card-list {
  .card-container {
    display: flex;
    justify-content: space-between;
  }
}
.news-list {
  margin-top: 80px;
  margin-bottom: 50px;
}
.btn {
  display: block;
  width: 172px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 25px;
  line-height: 50px;
  text-align: center;
  margin: 0 auto;
  color: #666;

  &:hover {
    background: #3b93d7;
    color: #fff;
    box-shadow: 0 0 8px 1px #d3ebff;
  }
}


@media (max-width: 768px) {

    .page {
    .banner {
      height: 2.48rem;
      .nav-controller{
        display:none;
      }
    }
  }
  .top-banner-swiper {
    max-width: 100%;
    width:100%;
    height: 2.48rem;
    .swiper-slide {
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .banner-progress {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    /deep/.swiper-pagination-bullet {
      width: .18rem;
      height: .18rem;
      margin: 0 .1rem;
    }
  }
  .intro-container {
    display: block;
    margin-top: .5rem;
    margin-bottom: .5rem;

    .intro-item {
      display:inline-block;
      vertical-align: top;
      width:50%;
      padding:0.2rem;
      background-size: 3.9rem 3rem;
      // height: 7rem;
      padding-top: 2rem;
      .intro-title {
        line-height: .8rem;
        font-size: .4rem;
        letter-spacing: .04rem;
      }
      .intro-desc {
        font-size: .25rem;
        margin-top: .2rem;
        // height: 2.5rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
        padding: 0;
        margin-bottom: 0.3rem;
      }
    }
  }
  .focus-entrance {
    margin-bottom: 0;
  }
  .participate-map-container {
    display: block;

    margin-bottom: 1rem;
    .map-container {
      width: 100%;
      margin-right: 0;
    }
    .participate-data-container {
      width: 100%;
      display: block;
      .item {
        display:inline-block;
        width:50%;
        background-position: 0 .05rem;
        background-size:.70rem .70rem;
        padding: 0 0 .2rem 0;
        margin-bottom:.2rem;
        margin-top:.4rem;

        .title {
          margin: 0 0 0 0.9rem;
          font-size: .32rem;

        }
        .data {
          margin: 0 0 0 0.9rem;
          font-size: .4rem;
        }
      }
    }
  }
  .news-block {
    padding-top: 0;
    padding-bottom: 2rem;
  }

  /deep/.card-list {
    .card-container {
      display: block;
    }
  }
  .news-list {
    margin-top: 80px;
    margin-bottom: 50px;
  }
  .btn {
    display: block;
    width: 172px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 25px;
    line-height: 50px;
    text-align: center;
    margin: 0 auto;
    color: #666;

    &:hover {
      background: #3b93d7;
      color: #fff;
      box-shadow: 0 0 8px 1px #d3ebff;
    }
  }
}

</style>