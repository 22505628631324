<template>
    <div class="page">
      <!-- <div class="banner"></div>  -->
        <div class="main-width-container">
            <div class="text-center">
                <div class="article-title">学生小组操作指南</div>
                <div class="cylct" @click="cylct()"></div>
            </div>
            <div class="text-content">
                <p>科学调查体验活动主要面向小学高年级及初中阶段学生，组织学生围绕活动官网提供的能源资源、生态环境、安全健康相关的17个活动主题，发现身边的科学问题，动手实践，体验探究。</p>
                <p>活动过程中，学生需组建学生小组（3-5人），并可在辅导老师（0-2人）的指导下，围绕主题开展小组学习，了解相关知识和研究方法，在学校、家庭、校外开展科学调查实践活动，最终通过活动网站提交调查实践报告等。</p>
                <div class="tip-box">
                <div class="tip-title">特别提示：</div>
                <div class="tip-content"> 组建学生小组时不需要组内的每个学生都分别注册账户，只有创建小组的学生需要注册账号，被添加的组员无需单独注册账号，否则可能无法被添加进小组。 </div>
                </div>
                <div class="text-title">
                    <video src="https://cysccresource.cyscc.org/video/file/3xueshengcaozuo.mp4" poster="../../assets/xs-poster.jpg" controls="controls" width="100%" height="100%" style="margin-bottom: 24px;"></video>
                <span>1.注册登录</span>
                </div>
                <p>和往年活动不同，2022年起流程有所简化，不再需要学生小组的每位成员都分别单独注册账号。只有创建小组的学生需要注册账号，被添加的组员无需单独注册账号，否则可能无法被添加进小组。</p>
                <p>比如一个小组五个同学，只需其中一位同学进行注册，创建小组。创建小组时，通过【手动添加】填写组员信息，将其他组员加入小组。</p>
                <div class="sub-text-title">
                <span>1.1注册</span>
                </div>
                <p>创建小组的学生若此前未注册过，可访问“青少年科学调查体验活动”官网( http://www.scienceday.org.cn/ )，点击右侧导航【注册】，将进入科创筑梦账号注册页面。注册时可使用邮箱或手机作为登录用户名。再次提醒：组内的组员无需单独注册。</p>
                <div class="tip-box">
                <div class="tip-title">小提示：</div>
                <div class="tip-content"> 若去年已经注册过账户，今年无需重新注册 </div>
                </div>
                <img src="https://cyscc.org/dctyhd/img/hd1.cb084b48.png" alt="">
                <div class="sub-text-title">
                <span>1.2登录</span>
                </div>
                <p>若已经有账号，点击官网右侧导航【登录】，进入科创筑梦登录页面，按提示输入【用户名】和【密码】，点击登录按钮，即可登录系统。</p>
                <img src="https://cyscc.org/dctyhd/img/hd2.13629dbe.png" alt="">
                <div class="text-title">
                <span>2.选择身份、完善信息</span>
                </div>
                <p>注册后首次登录，将进入身份选择界面。学生用户请选择【学生】身份。注意：学生需选择【学生】身份，请勿选择错误。</p>
                <img src="https://cyscc.org/dctyhd/img/xsxz1.57007e60.png" alt="">
                

                <p>选择完成身份后，将自动进入个人信息填写页面，请按页面要求填写。根据参与渠道的不同，可选择通过【就读学校】和【校外机构】两种渠道，两种参与方式仅可选择一种，可与辅导老师确认参与途径，信息提交后不可更改。</p>
                <div class="tip-box">
                <div class="tip-title">小提示：</div>
                <div class="tip-content">若学生是通过所在就读学校参与本活动，请选择【通过就读学校】；若是通过校外机构参与，请选择【通过校外机构】。校外机构指科技馆、科普教育基地、中小学研学实践教育基地、少先队校外实践教育营地（基地）、青少年活动中心、青少年宫等开展校外科技实践活动的非营利性机构或场所。 </div>
                </div>                  
                
                <div class="sub-text-title">
                <span>2.1通过就读学校参与</span>
                </div>                 
                <p>如选择通过就读学校参与，请按页面提示填写姓名、证件号、所在地区、学校、年级、班级等信息，并点击保存按钮，即可完成个人信息的填写。</p>
                <img src="https://newoa.cyscc.org/profile//castic/2024/06/20/223a0f15-9a37-4127-be83-996cfff5e0ae.jpg" alt="">
                <div class="tip-box">
                <div class="tip-title">小提示：</div>
                <div class="tip-content"> 【班级】可选一至十班，若您就读的班级不在其中，可选择“其他”，再根据实际情况手工填写相应的班级信息。 </div>
                </div>
                

                <div class="sub-text-title">
                <span>2.2通过校外机构参与</span>
                </div>                 
                <p>如选择通过校外机构参与，请按页面提示填写姓名、证件号、所在地区、机构、年级等信息，并点击保存按钮，即可完成个人信息的填写。</p>
                <img src="https://newoa.cyscc.org/profile//castic/2024/06/20/d604d2b2-d29f-4d13-89e6-247e37e712aa.jpg" alt="">

                <p> </p>

                
                <p>完成2.1或2.2的个人信息填写步骤后，即可着手参与活动，学生需在活动网站平台完成 <strong>创建小组、添加成员（每组总学生数3-5人）、添加老师（0-2人）、选择主题、提交报告</strong>五个步骤。具体操作见本页面的 <strong>第3-7节。</strong></p>
                
                <div class="text-title">
                <span>3.创建小组</span>
                </div>
                <p>在活动页面上，点击导航栏上的【小组管理】，在打开的页面上点击【创建小组】按钮，输入小组名称、添加组员(注意：每个小组总学生数是3-5人)和辅导老师(0-2人)，点击保存即完成小组创建。添加组员和辅导教师的说明见4和5。</p>
                <div class="tip-box">
                <div class="tip-title">小提示：</div>
                <div class="tip-content"> 一个账号可创建多个小组，每个小组最多可选择三个主题活动。同一个学生，最多同时参与三个不同小组，并这些小组需在同一个账户内，不能是不同账户的不同小组。对于相同的主题，同一学生仅可参与一次，最多参与三个不同主题。 </div>
                </div>
                <img src="https://cyscc.org/dctyhd/img/xsxz3.16447559.png" alt="">
                <p>创建完成小组后，在【小组管理】页面内将可看到已经创建的小组信息，以及完成活动的进展情况，并可对已经创建的小组进行编辑或删除。</p>
                <div class="tip-box">
                <div class="tip-title">小提示：</div>
                <div class="tip-content"> 只有活动状态显示为【已提交】时，该项活动才算在系统内提交完成。如果显示【未提交】，则需要在规定时间内完成提交，否则不可再提交报告。 </div>
                </div>
                <div class="text-title">
                <span>4.添加组员</span>
                </div>
                <p>在创建小组界面上点击【添加组员】按钮，即可进行组员的添加。每个小组学生总数至少3人，最多5人。添加成员有两种方式：手动添加和从列表中选择。</p>
                <img src="https://cyscc.org/dctyhd/img/xsxz4.610dde78.png" alt="">
                <div class="sub-text-title">
                <span>4.1从列表中选择组员</span>
                </div>
                <p>点击添加成员后，默认进入从列表中选择组员界面。如果是此前已经手动添加过的学生或登录账户的学生本人，则采用此方式，从列表中勾选需要添加的成员即可。若您要添加的学生，不在列表内参考4.2节进行手动添加。</p>
                <div class="tip-box">
                <div class="tip-title">小提示：</div>
                <div class="tip-content"> 若登录账户的学生本人也是组员之一，务必从列表中选择，账号注册者本人会出现在选择列表的第一个。 </div>
                </div>
                <img src="https://cyscc.org/dctyhd/img/xsxz5.5b46a068.png" alt="">
                <div class="sub-text-title">
                <span>4.2手动添加组员</span>
                </div>
                <p>如果是新添加的成员，则采用【手动添加】，需要按页面提示添加成员信息。如需添加登录账户的学生本人或其他已经添加过的学生，则【从列表中选择】添加。具体操作参见4.1节。</p>
                <img src="https://cyscc.org/dctyhd/img/xsxz6.85741c9d.png" alt="">
                <div class="sub-text-title">
                <span>4.3删除小组成员</span>
                </div>
                <p>成员添加后，将展示在创建小组界面上，如需删除相应的成员，可点击头像右上角的删除按钮。</p>
                <img src="https://cyscc.org/dctyhd/img/xsxz7.53717cd1.png" alt="">
                <div class="text-title">
                <span>5.添加辅导教师</span>
                </div>
                <p>在创建小组界面上点击【添加教师】按钮，即可进行辅导教师的添加。每个小组可添加0-2位教师。</p>
                <div class="tip-box">
                <div class="tip-title">小提示：</div>
                <div class="tip-content"> 辅导教师需完成相应的账号注册，并经过所在学校管理员审核才可被学生小组添加。 </div>
                </div>
                <img src="https://cyscc.org/dctyhd/img/xsxz8.d7f66fe9.png" alt="">
                <div class="sub-text-title">
                <span>5.1添加本校教师</span>
                </div>
                <p>对于本校教师，系统将列出相应的教师信息，勾选、保存后即可完成添加。</p>
                <img src="https://cyscc.org/dctyhd/img/xsxz9.d8db9e3a.png" alt="">
                <div class="sub-text-title">
                <span>5.2手动添加组员</span>
                </div>
                <p>对于外校教师，可通过授权码的方式添加。在外校教师添加页面上，输入教师提供的授权码，点击搜索，确认为要添加的教师后，点击【选择】按钮，即可完成添加。教师如何生成授权码请参考教师操作指南&gt;&gt;</p>
                <img src="https://cyscc.org/dctyhd/img/xsxz10.046ef3e8.png" alt="">
                <div class="sub-text-title">
                <span>5.3删除辅导教师</span>
                </div>
                <p>辅导教师添加后，将展示在创建小组界面上，如需删除相应的教师，可点击头像右上角的删除按钮。</p>
                <img src="https://cyscc.org/dctyhd/img/xsxz11.c1e929d2.png" alt="">
                <div class="text-title">
                <span>6.选择活动主题</span>
                </div>
                <p>创建完成小组后，在【小组管理】页面内找到需要选择活动主题的小组，点击对应的【选择活动按钮】，即可进入活动主题选择界面。</p>
                <img src="https://cyscc.org/dctyhd/img/xsxz12.58fdbe2d.png" alt="">
                <p>在【选择参与活动主题】页面右上方，点击【新增活动】，即可进行相应的活动主题选择。每个小组最多选择三个不同主题。</p>
                <img src="https://cyscc.org/dctyhd/img/xsxz13.dab4bbe5.png" alt="">
                <p>选中的活动主题将呈现在【选择参与活动主题】页面上。在该页面，可查看相应主题活动的完成状态、上传相应的主题活动报告并提交。</p>
                <img src="https://cyscc.org/dctyhd/img/xsxz14.fe4aa89c.png" alt="">
                <div class="text-title">
                <span>7.提交报告 (11月底前)</span>
                </div>
                <p>进入活动【选择参与活动主题】页面（进入方法参照3.4节），点击对应主题活动的【上传报告】按钮，根据页面提示上传符合要求的报告文档。确认无误后，点击【提交】按钮即可完成提交。</p>
                <div class="tip-box">
                <div class="tip-title">小提示：</div>
                <div class="tip-content"> 只有活动状态显示为【已提交】时，该项活动才算在系统内提交完成。如果显示【未提交】，则需要在规定时间内完成提交，否则不可再提交报告。 </div>
                </div>
                <img src="https://cyscc.org/dctyhd/img/xsxz15.d8f2eb43.png" alt="">
            </div>
            <div class="pop-mask" v-show="isShowPop" @click="closeMask">
                <div class="pop-box" @click="closeMask1">
                    <img src="../../assets/images/guideline/student-content.png" alt="">
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  
  export default {
    data () {
      return {
        isShowPop: false
      }
    },
    methods: {
        cylct(){
            this.isShowPop = true
        },
        closeMask(){
            this.isShowPop = false
        },
        closeMask1(e){
            e.stopPropagation()
        }
    }
  }
  </script>
  
  <style scoped lang="less">
  .page {
    .pop-mask{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.2);
        z-index: 999;
        overflow: auto;
   .pop-box{
         width: 900px;
         margin: 40px auto;
        background-color: #fff;
        img{
            width: 100%;
        }
     
  
     }
   
    }
    width: 890px;
    margin-left: 31px;
    padding-top: 55px;
    padding-bottom: 55px;
    min-width: 890px;
    .main-width-container{
      width: 890px;
      margin: 0 auto;
      .cylct{
        width: 128px;
        height: 32px;
        background:url('../../assets//images/guideline/cylct.png') no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        margin: 0 auto;
      }
      .text-content{
        margin-top: 48px;
        img{
            margin-bottom: 24px;
        }
        p{
            font-size: 16px;
            line-height: 32px;
            color: #000;
            margin-bottom: 24px;
             text-indent: 2em;
        }
        .text-title{
            font-size: 22px;
            font-weight: 700;
            line-height: 30px;
            color: #5293D3;
            position: relative;
            margin-bottom: 24px;
        }
        .sub-text-title{
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: #5293D3;
            margin-bottom: 24px;
        }
        .tip-box{
            width: 100%;
            padding: 10px;
            border-radius: 8px;
            border: 1px solid #2084E1;
            background-color: #EAF5FF;
            margin-bottom: 24px;
            
            
            .tip-title{
                font-size: 16px;
                font-weight: 700;
                line-height: 32px;
                color: #000000;
                display: inline-block;
            }
            .tip-content{
                font-size: 16px;
                line-height: 32px;
                color: #000000;
                display: contents;
            }

        }
        .text-title::after{
            content: '';
            display: block;
            width: 30px;
            height:29px;
            background: url('../../assets//images/guideline/Vector.png') no-repeat;
            background-size: 100% 100%;
            position: absolute;
            top: 0;
            left: 0;
            vertical-align: middle;
        }
        .text-title span{
            margin-left: 34px;
        }
      }
    }
    .article-title {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    line-height: 36px;
    margin-bottom: 24px;
  }
 
 
  }
  




  
  @media (max-width: 768px) {
    .page {
      background: none;
      min-height: 100%;
      width: 100%;
      min-width: 100%;
      padding-top: 1.5rem;
      padding-bottom: 0;
      margin-left: 0;
      .pop-mask {
    .pop-box {
      width: 100%;
      position: absolute;
      top: 50%;
        transform: translateY(-50%);
      img{
        width: 100%;
      }

    }
  }
      .main-width-container{
        width: 100%;
        margin: 0 auto;
        .text-content{
        margin-top:0.48rem;

            img{
            width: 100%;
            }
            p{
            line-height: 1.8;
            color: #000;
        }
       
        }
      
    }
    .article-title {
      font-size: 0.28rem;
      font-weight: 600;
      color: #111111;
      line-height: 0.32rem;
      margin-bottom: 0.24rem;
    

  }
 }
  }

 

  </style>