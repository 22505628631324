<template>
  <div class="page-with-menu ">
      <BreadCrumb :crumbData="breadCrumb"></BreadCrumb>
      <div class="list-title">{{zybName}}</div>
      <div class="zyb-video">
        <video :src="zybData.url" controls="controls" width="100%" height="100%" autoplay></video>
        </div>
      
</div>
</template>

<script>

import BreadCrumb from '@/components/BreadCrumb.vue';
import {findResourceById} from '../../api/api'
export default {
  components: {

    BreadCrumb
},
  data(){
    return {
      // 顶部面包屑
      breadCrumb:[
       {name:'活动指南',url:'/#/guideline/Nyzy'},{name:'相关资源',url:'/#/guideline/zyb/list/'+this.$route.params.id},{name:'视频'}
      ],
      // 资源包id
      zybId:null,
      // 文件ID
      fileId:null,
      // 文件名称
      zybName:null,
      // 文件数据
      zybData:{
        url:null
      }
     
    }
  },
  mounted(){

    this.zybId = this.$route.params.id;
    this.fileId = this.$route.params.fileId;
    // 根据zybId，获取对应资源包数据，初始化页面
    this.fetchData(this.fileId);
  },
  methods:{
    // 根据zybId，获取对应资源包数据，初始化页面
    async fetchData(fileId){
      const data = await findResourceById(fileId);
      this.zybName = data.data.title;
      this.zybData = {url:data.data.url};
      console.log(this.zybData)
    }
  }
}
</script>

<style scoped lang="less">
  .page-with-menu {
    padding-top: 55px;
    padding-left:30px;
  }
  .list-title{
    font-size:18px;
    font-weight: bold;
    text-align:center;
    margin:20px auto
  }
  .zyb-video{
    width:100%;
    margin:50px auto 20px;
    video{
      width:100%;
      
    }
  }
 
  @media (max-width: 798px) {
    .page-with-menu {
      padding-top: .3rem;
      padding-left:0;
    }
    .list-title{
    font-size:.32rem;

    margin:.32rem auto
  }
    .zyb-video{

      margin:.5rem auto 0;
      video{
        width:100%;
        
      }
    }
  }
</style>