<template>
  <div class="container" v-html="detail">
  </div>
</template>

<script>
import {findActivityIntroduction} from '@/api/api'
export default {
  data () {
    return {
      detail: {},
      videoCover:require('../../assets/images/participate/poster.jpeg')
    }
  },
  methods: {
    getDetail () {
      findActivityIntroduction().then(res => {
        if (res.code === 200) {
          this.detail = res.data
          this.$nextTick(()=>{
      let video = document.querySelector('video')
      if(video){
        video.poster = this.videoCover
      }
    })
        }
      })
    }
  },
  created () {
    this.getDetail()
  },
  mounted(){
   
  
    
    
  }
}
</script>

<style scoped lang="less">


@media (max-width: 768px) {
  
}
</style>